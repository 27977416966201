import React from 'react';
import { Input, FormGroup, Label, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import DatePickerComponent from '../DatePicker.component';

const testPriorityValues = ["All", "StatOnly", "NonStatOnly"]
const testValues = ["All", "ALT", "GLU"]
const technologyValues = ["All", "Slide", "Tip", "Well"]
const addOnsValues = ['Include', "Exclude"]
const reRunsValues = ["Include", "Exclude"]
const centrifugationValues = ["ALL", "On-Track", "Off-Track"]
const analyzerData = [{ name: "All", value: "ALL" }, { name: "46000944", value: "46000944" }, { name: "46000945", value: "46000945" }, { name: "46000946", value: "46000946" }]
const todaysDate = new Date();
var startDate = new Date();
startDate.setMonth(startDate.getMonth() - 3);


const translationMap = ({ t, values, pathToTn }) => values.map((v, i) => ({
    name: t(`${pathToTn}.${v}`),
    value: v
}))


class TurnAroundTimeDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                startDate: startDate,
                endDate: todaysDate,
                testPrioritySelect: testPriorityValues[0],
                testSelect: testValues[0],
                technologySelect: technologyValues[0],
                addOnsSelect: addOnsValues[0],
                reRunsSelect: reRunsValues[0],
                centrifugationSelect: centrifugationValues[0],
                analyzerSelect: analyzerData[0].name

            },
        }
    }
    handleChange = ({ target: { name, value } }) => {
        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                [name]: value
            }
        })
    }

    render() {
        const {
            t
        } = this.props
        const testData = translationMap({ values: testValues, t, pathToTn: "detailsCard.TATDetailsForm.test" })
        const testPriorityData = translationMap({ values: testPriorityValues, t, pathToTn: "detailsCard.TATDetailsForm.testPriority" })
        const technologyData = translationMap({ values: technologyValues, t, pathToTn: "detailsCard.TATDetailsForm.technology" })
        const addOnsData = translationMap({ values: addOnsValues, t, pathToTn: "detailsCard.TATDetailsForm.addOns" })
        const reRunsData = translationMap({ values: reRunsValues, t, pathToTn: "detailsCard.TATDetailsForm.reRuns" })
        const centrifugationData = translationMap({ values: centrifugationValues, t, pathToTn: "detailsCard.TATDetailsForm.centrifugation" })

        return (
            <>
                <div className="row">
                    <div className="col">

                        <DatePickerComponent
                            name="startDate"
                            onChange={this.handleChange}
                            selected={this.state.options.startDate || new Date()}
                            label={t("detailsCard.startDate")}
                        />

                        <DatePickerComponent
                            name="endDate"
                            onChange={this.handleChange}
                            selected={this.state.options.endDate || new Date()}
                            label={t("detailsCard.endDate")}
                        />
                        <FormGroup>
                            <Label for="testPrioritySelect">{t("detailsCard.testPriority")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="testPrioritySelect"
                                onChange={this.handleChange}
                            >
                                {
                                    testPriorityData.map((v, i) => <option key={i} value={v.value}>{v.name}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="testSelect">{t("detailsCard.test")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="testSelect"
                                onChange={this.handleChange}
                            >
                                <option value="All">{t("All")}</option>
                                {
                                    this.props.testOptions.map((v, i) => <option key={i} value={v.testID}>{v.testName}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="technologySelect">{t("detailsCard.Technology")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="technologySelect"
                                onChange={this.handleChange}
                            >
                                {
                                    technologyData.map((v, i) => <option key={i} value={v.value}>{v.name}</option>)
                                }
                            </Input>
                        </FormGroup>
                    </div>
                    <div className="col">
                        <FormGroup>
                            <Label for="addOnsSelect">{t("detailsCard.Add-ons")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="addOnsSelect"
                                onChange={this.handleChange}
                            >
                                {
                                    addOnsData.map((v, i) => <option key={i} value={v.value}>{v.name}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="reRunsSelect">{t("detailsCard.Re-runs")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="reRunsSelect"
                                onChange={this.handleChange}
                            >
                                {
                                    reRunsData.map((v, i) => <option key={i} value={v.value}>{v.name}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="centrifugationSelect">{t("detailsCard.Centrifugation")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="centrifugationSelect"
                                onChange={this.handleChange}
                            >
                                {
                                    centrifugationData.map((v, i) => <option key={i} value={v.value}>{v.name}</option>)
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="analyzerSelect">{t("detailsCard.Analyzer")}</Label>
                            <Input
                                bsSize="md"
                                type="select"
                                name="analyzerSelect"
                                onChange={this.handleChange}
                            >
                                label={t("detailsCard.startDate")}
                                <option value="All">{t("All")}</option>
                                {
                                    this.props.analyzerOptions.map((v, i) => <option key={i} value={v.moduleID}>{v.moduleName}</option>)
                                }
                            </Input>
                        </FormGroup>
                    </div>
                </div>
                <FormGroup className="m-0">
                    <Button onClick={() => this.props.handleViewButtonClick({ options: this.state.options })} className="w-100"> {t("detailsCard.view")} </Button>
                </FormGroup>
            </>
        )
    }
}


export default withTranslation()(TurnAroundTimeDetailsForm);