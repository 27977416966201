import React from 'react';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import VASLineChart from '../../components/VASFigure';
import VASGauge from "../../components/VASGauge";
import VASBoxPlot from "../../components/VASBoxPlot";


const VasDashboardClient = require('vas-dashboard-client');
const defaultClient = VasDashboardClient.ApiClient.instance;
defaultClient.basePath = process.env.REACT_APP_VAS_DASHBOARD_API_BACKEND_HOSTNAME;


class DashboardMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            options: []
        };
        this.formChange = this.formChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }


    changeLang = (language) => {
        i18n.changeLanguage(language);
    }


    componentDidMount() {
        let options = [];
        let apiInstance = new VasDashboardClient.DefaultApi();
        // let bearerAuth = defaultClient.authentications['bearerAuth'];
        // bearerAuth.accessToken = localStorage.getItem("encodedUserToken");
        //TODO: look at this???

        apiInstance.listCustomers((error, data) => {
            if (error)
                console.log("error: " + error);
            else
                data.forEach(d => options.push({ label: d['name'], value: d['id'] }))
        });

        this.setState({
            options: options
        });
    }

    formChange = (selectedOption) => {
        const val = selectedOption.value;
        this.setState({ value: val })
    }

    formSubmit(e) {
        alert("submit value: " + this.state.value)
        e.preventDefault();
    }

    render() {
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-8 mt-5">
                        <VASLineChart title="Chart 1" />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-6 mt-5">
                        <VASGauge />
                    </div>

                    <div className="col-6 mt-5">
                        <VASGauge />
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-8 mt-5 mb-5">
                        <VASBoxPlot />
                    </div>
                </div>

            </>
        );
    }
}

export default withTranslation()(DashboardMain)