import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/custom.css'
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import i18n from "i18next";
import {withTranslation} from "react-i18next";

/**
 * Shown to unauthenticated users
 */
class BasicNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false
        };
    }

    componentDidMount() {
        if (sessionStorage.getItem("encodedUserToken") != null)
            this.setState({
                loggedIn: true
            });
    }

    changeLang = (language) => {
        i18n.changeLanguage(language);
    }

    logout() {
        sessionStorage.removeItem('encodedUserToken');
        window.location.href = '/';
    }

    render() {
        const { t } = this.props;
        return (
            <Navbar className="vas-nav" variant="dark">
                <Navbar.Brand className="ml-1" href="#">Ortho Clinical Diagnostics</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/*<Nav.Link href="#home">Home</Nav.Link>*/}
                        {/*<Nav.Link href="#link">Link</Nav.Link>*/}
                    </Nav>
                    <Nav>
                        <NavDropdown alignRight className="mr-1" id="user_dropdown" title={
                            t("language")
                        }>
                            <NavDropdown.Item onClick={() => this.changeLang('en')}>English</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => this.changeLang('fr')}>français</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withTranslation()(BasicNavbar);