import React from "react";
import { Modal, Button } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import i18n from 'i18next';

class HelpTextModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSecondParagraph: false
    }
  }
  // checking if language got changed
  isLanguageFr = () => {
    return (i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en') === "fr";
  };

  render() {
    const {
      t,
      isOpen,
      showHideHelpTextModal,
      helpTextObject: { en, fr }
    } = this.props
    const { showSecondParagraph } = this.state

    // Modal's data is managed using modalDataObject
    const modalDataObject = {
      header: !this.isLanguageFr() ? en.title : fr.title,
      firstParagraph: !this.isLanguageFr() ? en.first : fr.first,
      secondParagraph: !this.isLanguageFr() ? en.second : fr.second,
      expandText: t("helpText.ExpandForMoreDetails"),
      collapseText: t("helpText.CollapseDetails")
    }

    return (
      <Modal show={isOpen} dialogClassName="modal-lg" onHide={showHideHelpTextModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalDataObject.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalDataObject.firstParagraph}
          {!showSecondParagraph &&
            <p
              className="cursor-pointer text-primary"
              onClick={() => this.setState({ showSecondParagraph: true })}>
              {modalDataObject.expandText}</p>}
          {showSecondParagraph && modalDataObject.secondParagraph}
          {showSecondParagraph &&
            <p
              className="cursor-pointer text-muted"
              onClick={() => this.setState({ showSecondParagraph: false })}>
              {modalDataObject.collapseText}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={showHideHelpTextModal}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(HelpTextModal)