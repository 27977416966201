import React from 'react';
import { FormGroup, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import DatePickerComponent from '../DatePicker.component';

// Set default dates for data acquisition - N months(s) ago to start and today to end
const todaysDate = new Date();
var startDate = new Date();
startDate.setMonth(todaysDate.getMonth() - 3);

class SampleLoadingDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                startDate: todaysDate,
            },
        }
    }
    handleChange = ({ target: { name, value } }) => {
        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                [name]: value
            }
        })
    }

    render() {
        const {
            t
        } = this.props

        return (
            <>
                <DatePickerComponent
                    name="startDate"
                    onChange={this.handleChange}
                    selected={this.state.options.startDate || new Date()}
                    label={t("detailsCard.date")}
                />

                <FormGroup className="">
                    <Button onClick={() => this.props.handleViewButtonClick({ options: this.state.options })} className="w-100"> {t("detailsCard.view")} </Button>
                </FormGroup>
            </>
        )
    }
}


export default withTranslation()(SampleLoadingDetailsForm);