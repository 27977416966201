import React from 'react';
import Select from "react-select";
import {Button, Card} from "react-bootstrap";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

const VasDashboardClient = require('vas-dashboard-client');
const defaultClient = VasDashboardClient.ApiClient.instance;
defaultClient.basePath = process.env.REACT_APP_VAS_DASHBOARD_API_BACKEND_HOSTNAME;


class OrgsSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            options: []
        };
        this.formChange = this.formChange.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
    }


    changeLang = (language) => {
        i18n.changeLanguage(language);
    }


    componentDidMount() {
        let options = [];
        let apiInstance = new VasDashboardClient.DefaultApi();
        // let bearerAuth = defaultClient.authentications['bearerAuth'];
        // bearerAuth.accessToken = localStorage.getItem("encodedUserToken");
        apiInstance.listCustomers((error, data) => {
            if (error)
                console.log("error: " + error);
            else
                data.forEach(d => options.push({label: d['name'], value: d['id']}))
        });

        this.setState({
            options: options
        });
    }

    formChange = (selectedOption) => {
        const val = selectedOption.value;
        this.setState({value: val})
    }

    formSubmit(e) {
        alert("submit value: " + this.state.value)
        e.preventDefault();
    }

    render() {
        const {t} = this.props;
        return (
            <div className="row justify-content-center">
                <div className="col-6 mt-5">
                <Card className="bg-secondary" style={{width: '50rem'}}>
                    <Card.Body>
                        <form onSubmit={this.formSubmit}>
                            <h1 className="display-3 text-dark mb-4">{t("orgs")}</h1>
                            <Select onChange={this.formChange} options={this.state.options} placeholder={
                                t('select.placeholder')
                            }/>
                            <Button className="mt-4" variant="primary" type="submit" value="Submit">
                                {t('buttons.submit')}
                            </Button>
                        </form>
                    </Card.Body>
                </Card>
                </div>
            </div>
        );
    }
}

export default withTranslation()(OrgsSelect)
