// import BaseStore from '../../BaseStore';
import jwt from 'jwt-decode';

// TODO: Centralize
// const LOGIN_USER = "LOGIN_USER";
// const LOGOUT_USER = "LOGOUT_USER";

const CLAIM_USERNAME = "CLAIM_USERNAME"
const CLAIM_DISPLAYNAME = "CLAIM_DISPLAYNAME"
const CLAIM_EMAIL = "CLAIM_EMAIL"
const CLAIM_USERID = "CLAIM_USERID"
const CLAIM_ACCOUNTNAME = "CLAIM_ACCOUNTNAME"
const CLAIM_FIRSTNAME = "CLAIM_FIRSTNAME"
const CLAIM_LASTNAME = "CLAIM_LASTNAME"
const CLAIM_USERTYPE = "CLAIM_USERTYPE"
const CLAIM_ORGANIZATIONCUSTOMERNUMBER = "CLAIM_ORGANIZATIONCUSTOMERNUMBER"
const CLAIM_UCNLIST = "CLAIM_UCNLIST"
const CLAIM_PRODUCTFAMILYLIST = "CLAIM_PRODUCTFAMILYLIST"
const CLAIM_PRODUCTTYPELIST = "CLAIM_PRODUCTTYPELIST"
const CLAIM_EQUIPMENTLIST = "CLAIM_EQUIPMENTLIST"
const CLAIM_FEATURELIST = "CLAIM_FEATURELIST"
const CLAIM_LOCALE = "CLAIM_LOCALE"

function displayClaim(claimName) {
    var encodedUserToken = sessionStorage.getItem("encodedUserToken");
    // Use JWT Decode (jwt-decode) to get usable token
    var decodedUserToken = jwt(encodedUserToken);

    var decodedClaim = "";

    switch (claimName) {
        case CLAIM_USERNAME:
            decodedClaim = decodedUserToken.username;
            break;
        case CLAIM_DISPLAYNAME:
            decodedClaim = decodedUserToken.displayname;
            break;
        case CLAIM_EMAIL:
            decodedClaim = decodedUserToken.email;
            break;
        case CLAIM_USERID:
            decodedClaim = decodedUserToken.userid;
            break;
        case CLAIM_ACCOUNTNAME:
            decodedClaim = decodedUserToken.accountname;
            break;
        case CLAIM_FIRSTNAME:
            decodedClaim = decodedUserToken.firstname;
            break;
        case CLAIM_LASTNAME:
            decodedClaim = decodedUserToken.lastname;
            break;
        case CLAIM_USERTYPE:
            decodedClaim = decodedUserToken.usertype;
            break;
        case CLAIM_ORGANIZATIONCUSTOMERNUMBER:
            decodedClaim = decodedUserToken.organizationcustomernumber;
            break;
        case CLAIM_UCNLIST:
            decodedClaim = decodedUserToken.ucnlist;
            break;
        case CLAIM_PRODUCTFAMILYLIST:
            decodedClaim = decodedUserToken.productfamilylist;
            break;
        case CLAIM_PRODUCTTYPELIST:
            decodedClaim = decodedUserToken.producttypelist;
            break;
        case CLAIM_EQUIPMENTLIST:
            decodedClaim = decodedUserToken.equipmentlist;
            break;
        case CLAIM_FEATURELIST:
            decodedClaim = decodedUserToken.featurelist;
            break;
        case CLAIM_LOCALE:
            decodedClaim = decodedUserToken.locale;
            break;
        default:
            break;
    };

    return decodedClaim;
}

class LoginStore /* extends BaseStore */ {

    constructor() {
        // super();
        // this.subscribe(() => this._registerToActions.bind(this))
        this._user = null;
        this._token = null;
        this._encodedUserToken = null;
    }

    displayUsername() {
        return displayClaim("CLAIM_USERNAME");
    }
        
    displayName() {
       return displayClaim("CLAIM_DISPLAYNAME");
    }

    displayEmail() {
        return displayClaim("CLAIM_EMAIL");
    }

    displayUserID() {
        return displayClaim("CLAIM_USERID");
    }

    displayAccountName() {
        return displayClaim("CLAIM_ACCOUNTNAME");
    }

    displayFirstName(CLAIM_FIRSTNAME) {
        return displayClaim("CLAIM_FIRSTNAME");
    }

    displayLastName() {
        return displayClaim("CLAIM_LASTNAME");
    }

    displayUserType() {
        return displayClaim("CLAIM_USERTYPE");
    }

    displayOrganizationCustomerNumber() {
        return displayClaim("CLAIM_ORGANIZATIONCUSTOMERNUMBER");
    }

    displayUCNList() {
        return displayClaim("CLAIM_UCNLIST");
    }

    displayProductFamilyList() {
        return displayClaim("CLAIM_PRODUCTFAMILYLIST");
    }

    displayProductTypeList() {
        return displayClaim("CLAIM_PRODUCTTYPELIST");
    }

    displayEquipomentList() {
        return displayClaim("CLAIM_EQUIPMENTLIST");
    }

    displayFeatureList() {
        return displayClaim("CLAIM_FEATURELIST");
    }
    
    displayLocale() {
        return displayClaim("CLAIM_LOCALE");
    }


    /*
    _registerToActions(action) {
        switch (action.actionType) {
            case LOGIN_USER:
                var decodedUserToken = JSON.parse(action.encodedUserToken);

                this._encodedUserToken = action.encodedUserToken;
                this._user = decodedUserToken.user;
                this._token = decodedUserToken.token;
                
                this.emitChange();
                break;
            case LOGOUT_USER:
                this._user = null;
                this.emitChange();
                break;
            default:
                break;
        };
    }
    */

    get user() {
        return this._user;
    }

    get token() {
        return this._token;
    }

    get accessToken() {
        if (this._token) {
            return this._token.accessToken;
        } else {
            return "";
        }

    }

    /**
     * Check whether token is expired on the client side
     * 
     * @return {boolean}
     */
    isTokenExpired() {
        if (!this._token) {
            return false;

        }

        if (!this._token || !this._token.hasOwnProperty('expiresIn')) {
            return false;
        }

        var now = new Date();
        var tokenExpiration = new Date(this._token.expiresIn);
        return (now > tokenExpiration);
    }

    /**
     * Approximate client side check if user is logged in
     * 
     * @return {boolean}
     */
    isLoggedIn() {
        /*
        if (this.isTokenExpired()) {
            return false;
        }

        return !!this._user;
        */

        return sessionStorage.getItem("encodedUserToken") != null;
    }

    /*
    isSystemAdmin() {
        if (!this._user) {
            return false;
        }
        
        return (this._user.system_role === "sysadmin");
    }
    */
}

export default new LoginStore();