import React from 'react';
import { Input, FormGroup, Label, Button } from "reactstrap";
import { withTranslation } from "react-i18next";
import DatePickerComponent from '../DatePicker.component';

const testSelectData = [
    {
        "name": "All",
        "value": "All"
    },
    {
        "name": "ALT",
        "value": "ALT"
    },
    {
        "name": "GLU",
        "value": "GLU"
    },
]
const testPrioritySelectData = [
    {
        "name": "All",
        "value": "All"
    },
    {
        "name": "Stat Only",
        "value": "Stat Only"
    },
]
const testPriorityValues = ["All", "StatOnly", "NonStatOnly"]
const testValues = ["All", "ALT", "GLU"]

const todaysDate = new Date();
var startDate = new Date();
startDate.setMonth(startDate.getMonth() - 3);

const translationMap = ({ t, values, pathToTn }) => values.map((v, i) => ({
    name: t(`${pathToTn}.${v}`),
    value: v
}))

class ProductionCardDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                startDate: startDate,
                endDate: todaysDate,
                testPrioritySelect: testPriorityValues[0],
                testSelect: testValues[0],
            },
            testSelectLabel: ""
        }
    }
    handleChange = ({ target: { name, value } }) => {
        this.setState({
            ...this.state,
            options: {
                ...this.state.options,
                [name]: value
            }
        })
    }

    render() {
        const {
            t
        } = this.props
        const testData = translationMap({ values: testValues, t, pathToTn: "detailsCard.TATDetailsForm.test" })
        const testPriorityData = translationMap({ values: testPriorityValues, t, pathToTn: "detailsCard.TATDetailsForm.testPriority" })
        return (
            <>
                <DatePickerComponent
                    name="startDate"
                    onChange={this.handleChange}
                    selected={this.state.options.startDate || new Date()}
                    label={t("detailsCard.startDate")}
                />
                <DatePickerComponent
                    name="endDate"
                    onChange={this.handleChange}
                    selected={this.state.options.endDate || new Date()}
                    label={t("detailsCard.endDate")}
                />
                <FormGroup>
                    <Label for="exampleSelect">{t("detailsCard.testPriority")}</Label>
                    <Input
                        type="select"
                        name="testPrioritySelect"
                        onChange={this.handleChange}
                    >
                        {
                            testPriorityData.map((v, i) => <option key={i} value={v.value}>{v.name}</option>)
                        }
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="exampleSelect">{t("detailsCard.test")}</Label>
                    <Input
                        type="select"
                        name="testSelect"
                        onChange={this.handleChange}
                    >
                        <option value="All">{t("All")}</option>
                        {
                            this.props.testOptions.map((v, i) => <option key={i} value={v.testID}>{v.testName}</option>)
                        }
                    </Input>
                </FormGroup>
                <FormGroup className="">
                    <Button onClick={() => this.props.handleViewButtonClick({ options: this.state.options, props: this.props.detailsOf })} className="w-100"> {t("detailsCard.view")} </Button>
                </FormGroup>
            </>
        )
    }
}


export default withTranslation()(ProductionCardDetailsForm);