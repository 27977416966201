import React from 'react'
import { Card, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import HighchartsWrapper from "./Utils/HighchartsWrapper"
import * as Scroll from "react-scroll";

import { FaLevelDownAlt, FaLevelUpAlt, FaQuestionCircle } from "react-icons/fa"

import vasFigureApiCallService from '../services/vasFigureApiCall.service';
import HelpTextModal from './Utils/HelpTextModal.component';
class VASFigureDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataIsLoaded: false,
            language: this.props.i18n.language,
            options: {
                data: {
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    moduleId: this.props.moduleID
                }
            },
            isModalOpen: false,
            apiData: null
        };
    }
    // apiData is managed from the vasFigureApiCallservice and 
    // passed as props to highcharts wrapper as data.
    UNSAFE_componentWillMount() {
        this.dataMap = async ({ apiOptions }) => {
            let allData = await vasFigureApiCallService({
                figureCode: this.props.figureCode,
                vasId: this.props.vasId,
                apiOptions
            });
            this.setState({
                dataIsLoaded: true,
                apiData: allData
            })
        }
    }

    // Helptext modal show hide logic
    showHideHelpTextModal = () => {
        this.setState({
            ...this.state,
            isModalOpen: !this.state.isModalOpen
        })
    }
    // requesting data on VASFigureDetails mount
    componentDidMount() {
        this.dataMap({
            apiOptions: this.state.options
        })
        // Added from hoc
        this.setState({
            detailsOf: null,
            detailsChanged: !this.state.detailsChanged
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.apiOptions !== prevProps.apiOptions
            || this.props.propsUpdated !== prevProps.propsUpdated
            || this.props.detailsChanged !== prevProps.detailsChanged
        ) {
            this.setState({
                dataIsLoaded: false
            })
            this.dataMap({
                apiOptions: this.props.apiOptions
            })
        }
    }

    handleProductionDataViewButtonClick = ({ options }) => {
        this.setState({
            productionCardDetailsFilter: {
                apiOptions: { data: { options } },
                testSelectLabel: options.testSelect ? `- ${options.testSelect}` : ""
            }
        })
    }

    handleSampleLoadingViewButtonClick = ({ options }) => {
        this.setState({
            sampleLoadingDetailsfilter: {
                apiOptions: { data: { options } },
            }
        })
    }
    handleTatViewButtonClick = ({ options }) => {
        this.setState({
            tatDetailsFilter: {
                apiOptions: { data: { options } },
            }
        })
    }

    render() {
        const {
            t,
            handleDetailsClick,
            handleCloseClick,
            figureCode,
            selectedOrgId,
            vasId,
            moduleID,
            labels,
            labelKey,
            extraDetailsOptions,
            extraOptions,
            scrollTo,
            scrollBackTo,
            detailsHelpTextObject
        } = this.props;

        return (
            this.state.dataIsLoaded
                ?
                <div className="row" id={figureCode} style={{ pageBreakAfter: "always", width: "100%" }}>
                    <Card className="w-100">
                        <Card.Body >
                            <div className="d-flex justify-content-between px-4">
                                <h5 className="text-dark mb-4">{labels?.containerLabel}</h5>
                                {/* <h6>{selectedOrgId}</h6> */}
                                {/* <Col md={6}> <h6>{figureCode}</h6> </Col> */}
                                <div className="pr-2"> {handleDetailsClick &&
                                    <>
                                        <Scroll.Link
                                            to={scrollTo}
                                            smooth={true}
                                            className="text-primary cursor-pointer"
                                            onClick={(e) => handleDetailsClick({
                                                selectedOrgId,
                                                vasId,
                                                figureCode,
                                                labelKey,
                                                extraDetailsOptions,
                                                detailsHelpTextObject
                                            })}
                                        >{t("highcharts.details")}<FaLevelDownAlt /></Scroll.Link>

                                    </>}
                                    {handleCloseClick && <Scroll.Link
                                        to={scrollBackTo}
                                        smooth={true}
                                        className="text-danger cursor-pointer"
                                        onClick={handleCloseClick}
                                    >{t("highcharts.close")}<FaLevelUpAlt /></Scroll.Link>}
                                    <FaQuestionCircle
                                        className="cursor-pointer ml-1"
                                        style={{ color: "#0056b3" }}
                                        onClick={this.showHideHelpTextModal}
                                    />
                                    {
                                        this.state.isModalOpen &&
                                        <HelpTextModal
                                            isOpen={this.state.isModalOpen}
                                            showHideHelpTextModal={this.showHideHelpTextModal}
                                            helpTextObject={handleCloseClick ? this.props?.detailsOf?.detailsHelpTextObject : this.props.helpTextObject}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="chart-wrapper">
                                <div className="chart-inner">
                                    <HighchartsWrapper
                                        propsUpdated={this.props.propsUpdated}
                                        detailsChanged={this.props.detailsChanged}
                                        // if !handleCloseClick then passing extraOptions, else passing extradetailsOptions
                                        chartOptions={!handleCloseClick ? extraOptions : this.props?.detailsOf?.extraDetailsOptions}
                                        chartTitle={this.props.chartTitle}
                                        labels={labels}
                                        apiData={this.state.apiData}
                                    />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
                : <Spinner animation="border" />
        );
    }
}

export default withTranslation()(VASFigureDetails);