import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from './Routes';

import './styles/bootstrap.scss';
import './styles/ortho-foundations.scss';
import './styles/common.scss';


class App extends React.Component {

    render() {
        return (
            <Router>
                <Routes />
            </Router>
        );
    }
}

export default App;
