import React from 'react'
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";

import { withTranslation } from "react-i18next";
import VASFigure from '../VASFigure';
import moment from 'moment'
import DetailsGraphComponent from '../Utils/DetailsGraph.component';
import ProductionCardDetailsForm from '../Utils/detailsForm/ProductionCardDetails.form';

import {
    SamplesLoadedPerDayEnHT,
    SamplesLoadedPerDayDetailsEnHT,
    TestsLoadedPerDayEnHT,
    TestsLoadedPerDayDetailsEnHT
} from "../Utils/helpTextBody/en/body.component";
import {
    SamplesLoadedPerDayFrHT,
    SamplesLoadedPerDayDetailsFrHT,
    TestsLoadedPerDayFrHT,
    TestsLoadedPerDayDetailsFrHT
} from "../Utils/helpTextBody/fr/body.component";

/* Set "today" as starting point */
//const today = moment().format("DD-MMM-YYYY");
//const oneYearAgo = moment().subtract(1, 'years').format("DD-MMM-YYYY");
//const threeMonthsAgo = moment().subtract(3, 'months').format("DD-MMM-YYYY");
const today = new Date();
let oneYearAgo = new Date();
oneYearAgo.setFullYear(today.getFullYear() - 1);
let threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(today.getMonth() - 3);

const testPriorityValues = ["All", "StatOnly", "NonStatOnly"]
const testValues = ["All", "ALT", "GLU"]
const VasDashboardClient = require('vas-dashboard-client');

class ProductionDataCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataIsLoaded: false,
            propsUpdated: true,
            detailsChanged: false,
            firstDetailsClick: true,
            sampleProdCardDetailsFilter: { testSelectLabel: 'ALL' },
            testProdCardDetailsFilter: { testSelectLabel: 'ALL' },
            //sampleLoadingDetailsfilter: {},
            //tatDetailsFilter: {},
            moduleValue: null,
            moduleName: null,
            options: {
                startDate: threeMonthsAgo,
                endDate: today,
                testPrioritySelect: testPriorityValues[0],
                testSelect: testValues[0],
            },
            testSelectLabel: ""
        }
    }

    handleCloseClick = () => {
        this.setState({
            ...this.state,
            detailsOf: null
        })
        // Scroll.animateScroll.scrollTo(scrollBackTo);
    }

    // handleDetailsClick is managed using the detailsOf object 
    // and reused everywhere in VasFigureDetails and DetailsGraph Component
    handleDetailsClick = (props) => {
        this.setState({
            ...this.state,
            detailsOf: {
                ...props,
                figureCode: `${props.figureCode}-details`,

            },
            firstDetailsClick: true,
            detailsChanged: !this.state.detailsChanged,
            // Reset Detail chart filters so that defaults are used for initial display
            sampleProdCardDetailsFilter: {},
            //sampleProdCardDetailsFilter: {
            //apiOptions: {
            //    data: {
            //        options: {
            //            endDate: today,
            //            startDate: threeMonthsAgo,
            //            testPrioritySelect: "All",
            //            testSelect: "All"
            //        }
            //    }
            //}
            //},
                testProdCardDetailsFilter: {},
            //testProdCardDetailsFilter: {
            //    apiOptions: {
            //        data: {
            //            options: {
            //                endDate: today,
            //                startDate: threeMonthsAgo,
            //                testPrioritySelect: "All",
            //                testSelect: "All"
            //            }
            //        }
            //    }
            //},
            sampleLoadingDetailsfilter: {},
            tatDetailsFilter: {}
        })
    }

    handleProductionDataViewButtonClick = ({ options, props }) => {
        let newDetailsOf = props;
        //newDetailsOf.extraDetailsOptions.subtitle.text = options.startDate.toDateString() + ' 1-1 ' + options.endDate.toDateString() + '<br/>' + 'Test Priority: ' + options.testPrioritySelect + '<br/>Test: ' + options.testSelect;
        this.setState({
            sampleProdCardDetailsFilter: {
                apiOptions: { data: { options } },
                testSelectLabel: ''
            },
            testProdCardDetailsFilter: {
                apiOptions: { data: { options } },
                testSelectLabel: ''
            },
            firstDetailsClick: false,
            detailsOf: {
                ...newDetailsOf,
                figureCode: `${props.figureCode}`,
            },
        })
    }

    componentDidMount() {
        this.setState({
            detailsOf: null,
            detailsChanged: !this.state.detailsChanged
        });
    }

    componentDidUpdate(prevProps) {
        // Make sure the orgId actually changed before doing anything
        if (this.props.selectedOrgId !== prevProps.selectedOrgId) {
            if (this.props.selectedOrgId) {
                this.setState({
                    dataIsLoaded: true,
                    detailsOf: null
                })
            }
            // this.fetchData(this.props.selectedOrgId);
        }
        // if any previous properties of graph changes graph reloads.
        if (prevProps !== this.props) {
            this.setState({
                propsUpdated: !this.state.propsUpdated
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            vasId: nextProps.selectedVasId,
            dataIsLoaded: false // cause data to reload when Org/IM selection changes
        })
        // Module select options
        if (nextProps.selectedVasId != null) {
            let apiInstance = new VasDashboardClient.DefaultApi();
            // Test (assay) select options for details filter
            apiInstance.getTestCodes(nextProps.selectedVasId, (error, data) => {
                if (error)
                    console.log("error: " + error);
                else {
                    this.setState({ testOptions: data });
                }
            });
        }
    }

    render() {
        const { t, sampleProdCardDetailsFilter, testProdCardDetailsFilter } = this.props;
        const scrollTo = "detailsof-productionCard"
        const scrollBackTo = "productionCard"
        const detailsEndDate = new Date();
        const detailsStartDate = new Date();
        detailsStartDate.setMonth(detailsEndDate.getMonth() - 3);

        let prodSampleFilterOptions = this.state.sampleProdCardDetailsFilter.apiOptions || {
            data: {
                options: {
                    endDate: today,
                    startDate: threeMonthsAgo,
                    testPrioritySelect: "All",
                    testSelect: "All"
                }
            }
        }

        let prodTestFilterOptions = this.state.testProdCardDetailsFilter.apiOptions || {
            data: {
                options: {
                    endDate: today,
                    startDate: threeMonthsAgo,
                    testPrioritySelect: "All",
                    testSelect: "All"
                }
            }
        }

        // component is managed using HOC (detailsviewHoc) which consists of all the common logics and some state management
        // Each Card consists of VasFigure and a DetailsGraphComponent to manage summary and details graphs
        // If Details click is avaliable then handleDetails click Property  and extraDetailsOptions(i.e. details chartoptions) is required
        // else only extraOptions(i.e. chartoptions ) is required
        // Helptext is handled using helptextObject and detailsHelpTextobject
        return (
            this.state.dataIsLoaded
                ? <Row style={{ paddingBottom: "15px" }} >

                    <Col md={{ size: 12 }}>
                        {/* PRODUCTION DATA */}
                        <Card className="card-default" id={scrollBackTo}>
                            <CardHeader>
                                {t("card.productionData")} | {this.props.selectedOrg.orgId}
                            </CardHeader>
                            <CardBody>
                                <Row >
                                    {/* Samples Loaded Per Day */}
                                    <Col lg={{ size: 6 }}>
                                        {/* 1.0 */}
                                        <VASFigure
                                            propsUpdated={this.state.propsUpdated}
                                            selectedOrgId={this.props.selectedOrgId}
                                            vasId={this.props.selectedOrg.vasId}
                                            figureCode="samples-loaded-per-day"
                                            startDate={oneYearAgo} /* today - 12 months */
                                            endDate={today} /* today */
                                            scrollTo={scrollTo}
                                            handleDetailsClick={this.handleDetailsClick}
                                            extraOptions={{
                                                chart: {
                                                    zoomType: "xy",
                                                    spacingTop: 60,
                                                    spacingBottom: 60,
                                                    events: {
                                                        load: function () {
                                                            let statCount = 0;
                                                            let nonStatCount = 0;
                                                            let totalCount = 0;
                                                            if (this.series[0].data.length > 0) {
                                                                this.series[0].data.forEach(element => {
                                                                    statCount += element.y;
                                                                })
                                                            }
                                                            if (this.series[1].data.length > 0) {
                                                                this.series[1].data.forEach(element => {
                                                                    nonStatCount += element.y;
                                                                })
                                                            }
                                                            if (this.series[2].data.length > 0) {
                                                                this.series[2].data.forEach(element => {
                                                                    totalCount += element.y;
                                                                })
                                                            }
                                                                let ren = this.renderer;
                                                            ren.label(t("highcharts.totalStats") + ': <b>' + statCount.toLocaleString('en') + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("highcharts.totalRoutines") + ': <b>' + nonStatCount.toLocaleString('en') + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("highcharts.totalSamples") + ': <b>' + totalCount.toLocaleString('en') + '</b>', 60, this.plotHeight + 140).css({}).add();
                                                        }
                                                    },
                                                },
                                                title: {
                                                    text: null
                                                },
                                                xAxis: [{
                                                    type: "datetime",
                                                    tickInterval: 30 * 24 * 3600 * 1000, // 1 month
                                                    endOnTick: false,
                                                    startOnTick: false,
                                                    dateTimeLabelFormats: {
                                                        day: '%e-%b',
                                                        week: '%b %e',
                                                        month: '%b',
                                                        // hours: '%e'
                                                    },
                                                }],
                                                yAxis: {
                                                    title: {
                                                        text: t("highcharts.samplesLoadedPerday.yaxisTitle"),
                                                    },
                                                },
                                                //subtitle: {
                                                //    align: 'left',
                                                //    x: 20,
                                                //    useHTML: true,
                                                //    verticalAlign: 'top',
                                                //    text: t(`highcharts.totalSamples`) + ': ' + this.props.figureCode + '<br/>' + t(`highcharts.totalStats`) + ': ' + this.props.figureCode + '<br/>' + t(`highcharts.totalRoutines`) + ': ' + this.props.figureCode + '<br/>'
                                                //},
                                                tooltip: {
                                                    headerFormat: '<span style="font-size:12px;font-weight:bold;">{point.key}</span><table>',
                                                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                    footerFormat: '</table>',
                                                    shared: false,
                                                    useHTML: true
                                                },
                                                plotOptions: {
                                                    series: {
                                                        marker: {
                                                            enabled: false
                                                        },
                                                        events: {
                                                            legendItemClick: (function (component) {
                                                                return function () {

                                                                    var bUseStat = true;
                                                                    var bUseNonStat = true;
                                                                    var bMAVClicked = false;
                                                                    const STAT = 0;
                                                                    const NONSTAT = 1;
                                                                    const TOTAVG = 3;
                                                                    const STATAVG = 4;
                                                                    const NONSTATAVG = 5;
                                                                    const ORIGINAL7DMA = 6;
                                                                    const ZERO7DMA = 7;

                                                                    let legendItem = this.legendItem;

                                                                    let stat = this.chart.series[STAT];
                                                                    let nonstat = this.chart.series[NONSTAT];
                                                                    let statAvg = this.chart.series[STATAVG];
                                                                    let nonAvg = this.chart.series[NONSTATAVG];
                                                                    let totAvg = this.chart.series[TOTAVG];
                                                                    let dmaAvg = this.chart.series[ORIGINAL7DMA];
                                                                    let zeroAvg = this.chart.series[ZERO7DMA];

                                                                    if (legendItem.textStr === t("legends.7DayMovingAverage")) {
                                                                    bMAVClicked = true;
                                                                    } else
                                                                            if (legendItem.textStr === t("Stat")) { //'Stat') {
                                                                            // Is Stat visible? If so, it is being removed. If not, it is being added. Set to inverse of visible.
                                                                            bUseStat = !stat.visible;
                                                                            // Retain value for Non-Stat
                                                                            bUseNonStat = nonstat.visible;
                                                                            } else if (legendItem.textStr === t("legends.NonStat")) { //'Non-Stat') {
                                                                            // Is Non-Stat visible? If so, it is being removed. If not, it is being added. Set to inverse of visible.
                                                                            bUseNonStat = !nonstat.visible;
                                                                            // Retain value for Stat
                                                                            bUseStat = stat.visible;
                                                                        }

                                                                    if (bMAVClicked) {
                                                                        if (totAvg.visible) {
                                                                            totAvg.update({ visible: false });
                                                                        }
                                                                        else {
                                                                            totAvg.update({ visible: true });
                                                                            if (stat.visible && nonstat.visible) {
                                                                                totAvg.setData(dmaAvg.data);
                                                                            } else if (stat.visible) {
                                                                                totAvg.setData(statAvg.data);
                                                                            } else if (nonstat.visible) {
                                                                                totAvg.setData(nonAvg.data);
                                                                            } else {
                                                                                totAvg.setData(zeroAvg.data);
                                                                            }
                                                                        }
                                                                    }
                                                                    else if (bUseStat && bUseNonStat) {
                                                                        stat.update({ visible: true }); nonstat.update({ visible: true });
                                                                        if (totAvg.visible) {
                                                                            // use both: base 7-Day series on Total (totalmavg) - hide others
                                                                            totAvg.setData(dmaAvg.data);
                                                                        }
                                                                    } else if (bUseStat) {
                                                                        stat.update({ visible: true }); nonstat.update({ visible: false });
                                                                        // use stat: base 7-Day series on stat (statmavg) - hide others
                                                                        if (totAvg.visible) {
                                                                            totAvg.setData(statAvg.data);
                                                                        }
                                                                    } else if (bUseNonStat) {
                                                                        stat.update({ visible: false }); nonstat.update({ visible: true });
                                                                        if (totAvg.visible) {
                                                                            // use nonstat: base 7-Day series on nonstat (nonstatmavg) - hide others
                                                                            totAvg.setData(nonAvg.data);
                                                                        }
                                                                    } else {
                                                                        stat.update({ visible: false }); nonstat.update({ visible: false });
                                                                        if (totAvg.visible) {
                                                                            // stat & nonstat disabled; use original 7 Day MA
                                                                            totAvg.setData(zeroAvg.data);
                                                                        }
                                                                    }
                                                                    // Don't let highcharts redraw chart
                                                                    return false;

                                                                }
                                                            })(this)
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    verticalAlign: 'bottom'
                                                },
                                                series: [
                                                    {
                                                        // 0. Stat Samples
                                                        name: t("legends.Stat"),
                                                        stacking: "normal",
                                                        type: "column"
                                                    },
                                                    {
                                                        // 1. NonStat Samples
                                                        name: t("legends.NonStat"),
                                                        stacking: "normal",
                                                        type: "column"
                                                    },
                                                    {
                                                        // 2. Total Samples
                                                        name: t("legends.Total"),
                                                        type: "column",
                                                        visible: false,
                                                        showInLegend: false
                                                    },
                                                    {
                                                        // 3. 7 Day Moving Avg
                                                        name: t("legends.7DayMovingAverage"),
                                                        type: "spline"
                                                    },
                                                    {
                                                        // 4. Stat 7 DMA
                                                        name: t("legends.7DayMovingAverage"),
                                                        type: "spline",
                                                        visible: false,
                                                        showInLegend: false
                                                    },
                                                    {
                                                        // 5. NonStat 7 DMA
                                                        name: t("legends.7DayMovingAverage"),
                                                        type: "spline",
                                                        visible: false,
                                                        showInLegend: false
                                                    },
                                                    {
                                                        // 6. 
                                                        name: t("legends.7DayMovingAverage"),
                                                        type: "spline",
                                                        visible: false,
                                                        showInLegend: false
                                                    },
                                                    {
                                                        // 7. No Data 7 DMA
                                                        name: t("legends.7DayMovingAverage"),
                                                        type: "spline",
                                                        visible: false,
                                                        showInLegend: false
                                                    }
                                                ]
                                            }}
                                            extraDetailsOptions={{
                                                chart: {
                                                    zoomType: "xy",
                                                    spacingTop: 80,
                                                    events: {
                                                        load: function () {
                                                            if (this.series[1].data.length > 0) {
                                                                let labels = this.series[1].data[0].y;
                                                                let ren = this.renderer;
                                                                ren.label("<b>" + t("detailsCard.startDate") + '</b>: ' + labels[0], 30, 0).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.endDate") + '</b>: ' + labels[1], 30, 15).css({}).add();
                                                                ren.label("<b>" + t("highcharts.totalSamples") + '</b>: ' + labels[4], 30, 30).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.testPriority") + '</b>: ' + labels[2], 250, 0).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.test") + '</b>: ' + labels[3], 250, 15).css({}).add();
                                                            }
                                                        }
                                                    },
                                                },
                                                title: {
                                                    text: null
                                                },
                                                xAxis: {
                                                    endOnTick: false,
                                                    startOnTick: false,
                                                    tickInterval: 1,
                                                    title: {
                                                        text: t(`highcharts.testsLoadedPerDay.xaxisTitle`)
                                                    }
                                                },
                                                yAxis: {
                                                    title: {
                                                        text: t(`highcharts.samplesLoadedPerday.yaxisTitle`),
                                                    }
                                                },
                                                tooltip: {
                                                    headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ': {point.key}</span><table>',
                                                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                    footerFormat: '</table>',
                                                    shared: false,
                                                    useHTML: true
                                                },
                                                plotOptions: {
                                                    series: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    verticalAlign: 'bottom',
                                                },
                                                series:
                                                    [
                                                    {
                                                        name: t("highcharts.samplesLoadedPerday.yaxisTitle"),
                                                        type: "line"
                                                        }
                                                        ,
                                                    {
                                                        name: '',
                                                        showInLegend: false
                                                    }
                                                ]
                                            }}
                                            labelKey={"samplesLoadedPerday"}
                                            labels={{
                                                containerLabel: t("highcharts.samplesLoadedPerday.containerLabel"),
                                                yaxisTitle: t("highcharts.samplesLoadedPerday.yaxisTitle"),
                                            }}

                                            helpTextObject={{ en: SamplesLoadedPerDayEnHT, fr: SamplesLoadedPerDayFrHT }}
                                            detailsHelpTextObject={{ en: SamplesLoadedPerDayDetailsEnHT, fr: SamplesLoadedPerDayDetailsFrHT }}
                                        />
                                    </Col>

                                    {/* Tests Loaded Per Day */}
                                    <Col lg={{ size: 6 }} >
                                        {/* 2.0 */}
                                        <VASFigure
                                            propsUpdated={this.state.propsUpdated}
                                            selectedOrgId={this.props.selectedOrgId}
                                            vasId={this.props.selectedOrg.vasId}
                                            figureCode="tests-loaded-per-day"
                                            startDate={oneYearAgo} /* today - 12 months */
                                            endDate={today} /* today */
                                            scrollTo={scrollTo}
                                            handleDetailsClick={this.handleDetailsClick}
                                            extraOptions={{
                                                title: {
                                                    text: null
                                                },
                                                chart: {
                                                    zoomType: "xy",
                                                    spacingTop: 60,
                                                    spacingBottom: 60,
                                                    events: {
                                                        load: function () {
                                                            let totalCount = 0;
                                                            if (this.series[0].data.length > 0) {
                                                                this.series[0].data.forEach(element => {
                                                                    totalCount += element.y;
                                                                })
                                                            }
                                                            let ren = this.renderer;
                                                            ren.label(t("highcharts.totalTests") + ': <b>' + totalCount.toLocaleString('en') + '</b>', 60, this.plotHeight + 140).css({}).add();
                                                        }
                                                    },
                                                },
                                                xAxis: {
                                                    type: "datetime",
                                                    tickInterval: 30 * 24 * 3600 * 1000, // 1 month
                                                    endOnTick: false,
                                                    startOnTick: false,
                                                    dateTimeLabelFormats: {
                                                        day: '%e-%b',
                                                        week: '%b %e',
                                                        month: '%b',
                                                        // hours: '%e'
                                                    },
                                                },
                                                yAxis: {
                                                    title: {
                                                        text: t("highcharts.testsLoadedPerDay.yaxisTitle"),
                                                    },
                                                },
                                                tooltip: {
                                                    headerFormat: '<span style="font-size:12px;font-weight:bold;">{point.key}</span><table>',
                                                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                    footerFormat: '</table>',
                                                    shared: false,
                                                    useHTML: true
                                                },
                                                plotOptions: {
                                                    series: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    verticalAlign: 'bottom',
                                                },
                                                series: [
                                                    {

                                                        name: t("legends.Tests"),
                                                        stacking: "normal",
                                                        type: "column"
                                                    },
                                                    {

                                                        name: t("legends.7DayMovingAverage"),
                                                        type: "spline"
                                                    }
                                                ]
                                            }}
                                            extraDetailsOptions={{
                                                chart: {
                                                    zoomType: "xy",
                                                    spacingTop: 80,
                                                    events: {
                                                        load: function () {
                                                            if (this.series[1].data.length > 0) {
                                                                let labels = this.series[1].data[0].y;
                                                                let ren = this.renderer;
                                                                ren.label('<b>' + t("detailsCard.startDate") + '</b>: ' + labels[0], 30, 0).css({}).add();
                                                                ren.label('<b>' + t("detailsCard.endDate") + '</b>: ' + labels[1], 30, 15).css({}).add();
                                                                ren.label('<b>' + t("highcharts.totalTests") + '</b>: ' + labels[4], 30, 30).css({}).add();
                                                                ren.label('<b>' + t("detailsCard.testPriority") + '</b>: ' + labels[2], 250, 0).css({}).add();
                                                                ren.label('<b>' + t("detailsCard.test") + '</b>: ' + labels[3], 250, 15).css({}).add();
                                                            }
                                                        }
                                                    },
                                                },
                                                title: {
                                                    text: null
                                                },
                                                xAxis: {
                                                    endOnTick: false,
                                                    startOnTick: false,
                                                    tickInterval: 1,
                                                    title: {
                                                        text: t(`highcharts.testsLoadedPerDay.xaxisTitle`)
                                                    }
                                                },
                                                yAxis: {
                                                    title: {
                                                        text: t(`highcharts.testsLoadedPerDay.yaxisTitle`),
                                                    }
                                                },
                                                tooltip: {
                                                    headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ': {point.key}</span><table>',
                                                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                    footerFormat: '</table>',
                                                    shared: false,
                                                    useHTML: true
                                                },
                                                plotOptions: {
                                                    series: {
                                                        marker: {
                                                            enabled: false
                                                        }
                                                    }
                                                },
                                                legend: {
                                                    verticalAlign: 'bottom',
                                                },
                                                series: [
                                                    {
                                                        name: t("highcharts.testsLoadedPerDay.yaxisTitle"),
                                                        type: "line"
                                                    }
                                                    ,
                                                    {
                                                        name: '',
                                                        showInLegend: false
                                                    }
                                                ]
                                            }}
                                            labelKey={"testsLoadedPerDay"}
                                            labels={{
                                                containerLabel: t("highcharts.testsLoadedPerDay.containerLabel"),
                                                yaxisTitle: t("highcharts.testsLoadedPerDay.yaxisTitle"),
                                            }}

                                            helpTextObject={{ en: TestsLoadedPerDayEnHT, fr: TestsLoadedPerDayFrHT }}
                                            detailsHelpTextObject={{ en: TestsLoadedPerDayDetailsEnHT, fr: TestsLoadedPerDayDetailsFrHT }}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </Col>

                    <Col md={{ size: 12 }} className="px-0" id={scrollTo}>
                        {
                            this.state.detailsOf?.figureCode === "samples-loaded-per-day-details" &&
                            <DetailsGraphComponent
                                detailsOf={this.state.detailsOf}
                                availableOrgs={this.props.availableOrgs}
                                vasId={this.props.selectedOrg.vasId}
                                startDate={threeMonthsAgo} /* today - 3 months */
                                endDate={today} /* today */
                                handleOrgDetailsSelectChange={this.handleOrgDetailsSelectChange}
                                handleCloseClick={this.handleCloseClick}
                                scrollBackTo={scrollBackTo}

                                labelKey={this.state.detailsOf.labelKey}
                                labels={{
                                    containerLabel: t(`highcharts.${this.state.detailsOf.labelKey}.containerLabel`),
                                    yaxisTitle: t(`highcharts.${this.state.detailsOf.labelKey}.yaxisTitle`),
                                    subTitle: ''
                                }}
                                detailsCardHeader={`${t("detailsCard.productionDataDetails")} | ${t("org")}: ${this.props.selectedOrgId}`}
                                detailsChanged={this.state.detailsChanged}

                                helpTextHeader={t(`helpText.${this.state.detailsOf.labelKey}.header`)}
                                helpTextBody={t(`helpText.${this.state.detailsOf.labelKey}.body`)}

                                detailsFormComponent={ProductionCardDetailsForm}
                                apiOptions={prodSampleFilterOptions}
                                handleViewButtonClick={this.handleProductionDataViewButtonClick}
                                testOptions={this.state.testOptions}
                                firstDetailsClick={this.state.firstDetailsClick}

                            />
                        }
                    </Col>
                    <Col md={{ size: 12 }} className="px-0" id={scrollTo}>
                        {
                            this.state.detailsOf?.figureCode === "tests-loaded-per-day-details" &&
                            <DetailsGraphComponent
                                detailsOf={this.state.detailsOf}
                                availableOrgs={this.props.availableOrgs}
                                vasId={this.props.selectedOrg.vasId}
                                startDate={threeMonthsAgo} /* today - 3 months */
                                endDate={today} /* today */
                                handleOrgDetailsSelectChange={this.handleOrgDetailsSelectChange}
                                handleCloseClick={this.handleCloseClick}
                                scrollBackTo={scrollBackTo}

                                labelKey={this.state.detailsOf.labelKey}
                                labels={{
                                    containerLabel: t(`highcharts.${this.state.detailsOf.labelKey}.containerLabel`),
                                    yaxisTitle: t(`highcharts.${this.state.detailsOf.labelKey}.yaxisTitle`),
                                    subTitle: ''
                                }}
                                detailsCardHeader={`${t("detailsCard.productionDataDetails")} | ${t("org")}: ${this.props.selectedOrgId}`}
                                detailsChanged={this.state.detailsChanged}

                                helpTextHeader={t(`helpText.${this.state.detailsOf.labelKey}.header`)}
                                helpTextBody={t(`helpText.${this.state.detailsOf.labelKey}.body`)}

                                detailsFormComponent={ProductionCardDetailsForm}
                                apiOptions={prodTestFilterOptions}
                                handleViewButtonClick={this.handleProductionDataViewButtonClick}
                                testOptions={this.state.testOptions}
                                firstDetailsClick={this.state.firstDetailsClick}

                            />
                        }
                    </Col>
                </Row>
                : null
        );
    }
}
//export default detailsViewHoc(withTranslation()(ProductionDataCard));
export default withTranslation()(ProductionDataCard)
