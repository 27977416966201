import React from "react";
import { MdOutlineMenu } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa"

export const WelcomeFrHT = {
    title: "fr-Title",
    first: <h1>fr-First</h1>,
    second: <h1>fr-Second</h1>,
}

// Production Dashboard
export const SamplesLoadedPerDayFrHT = {
    title: "fr-Samples Per Day Summary Help",
    first: <div>This graph provides a snapshot of the samples loaded onto VAS over time. Easily spot trends and determine if there is an increase or decrease in samples in your workload. Additionally, determine if the samples are being run as STAT.</div>,
    second: <div>
        <p>Unique samples used for Controls and Calibrations are also reflected in the graph if track loaded. </p>

        <p>The stacked bars are per day and delineate between samples run as a STAT sample on the analyzer and those that are run in a routine manner. STAT reflects the priority in your LIS, or if it was loaded through a STAT drawer on the Entry module.</p>

        <p>In addition to the stacked bars, there is a 7 Day Moving Average of the number of samples.</p>

        <ul>
            <li>To remove the STAT, Non-STAT or 7 Day Moving Average series from the graph, click on the series in the legend above the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time frame, click within the chart area and drag across dates.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart.</li>
            <li>To see the specific data, including date and number of samples, hover the mouse over any data point (STAT, Non-STAT or 7 Day Moving Average on any day).</li>
            <li>To perform additional review and analysis of the samples per day data, click the "Details" link to adjust parameters. </li>
            <li>Click the  <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const SamplesLoadedPerDayDetailsFrHT = {
    title: "fr-Samples Per Day Detail Help",
    first: <div>
        <p>This graph provides information about the number of samples loaded and what hour of the day. It is possible to select start and end dates and sample priority.</p>
        <p>The graph shows the average number of samples with the selected test across the 24 hours of the day (X-Axis) starting at Midnight (0) and running through 11:00 PM or 23:00 (23).</p>
    </div>,
    second: <div>
        <p>Unique samples used for Controls and Calibrations are also reflected in the graph if track loaded. The data reflects the average number of samples per hour across the time frame indicated by the start and end date parameters. </p>

        <p>Three parameters displayed on the graph can be adjusted: Start Date, End Date and Test Priority.</p>

        <ul>
            <li>To adjust the date range, change the Start and/or the End Date.</li>
            <li>The data averages all samples (STAT and Non-STAT). To limit to just STAT or Non-STAT, adjust the Test Priority parameter. </li>
        </ul>

        <p>Note: After adjusting any of the parameters, click the Blue "View" to refresh the data.</p>

        <p>Additional functionality includes:</p>

        <ul>
            <li>To zoom in to a specific time frame, click within the chart area and drag across dates.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart.</li>
            <li>To see the specific data, including hour and number of samples, hover the mouse over any hourly data point. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
            <li>To close the Details graph and return to the dashboard summary screen, Click "Close" next to the <FaQuestionCircle style={{ color: "#0056b3" }} />.</li>
        </ul>

    </div>
}

export const TestsLoadedPerDayFrHT = {
    title: "fr-Tests Per Day Summary Help",
    first: <div>This graph provides a snapshot of the tests loaded onto VAS over time. Easily spot trends and determine if there is an increase or decrease in tests in your workload. </div>,
    second: <div>
        <p>This graph displays the number of tests per day. Tests used for Controls and Calibrations are also reflected in the graph if track loaded. In addition, there is a 7-day moving average of the number of tests. </p>

        <ul>
            <li>To remove the Tests or 7 Day Moving Average series from the graph, click on the series in the legend above the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time frame, click within the chart area and drag across dates.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart.</li>
            <li>To see the specific data, including date and number of tests, hover the mouse over any data point.</li>
            <li>To perform additional review and analysis of the samples per day data, click the "Details" link to adjust parameters. </li>
            <li>Click the  <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const TestsLoadedPerDayDetailsFrHT = {
    title: "fr- Tests Per Day Detail Help",
    first: <div>
        <p>his graph provides information about what tests were loaded and during which part of the day they are performed. It is possible to select start and end dates, test priority and test type.</p>
        <p>The graph shows the average number of tests based on the selected test across the 24 hours of the day (X-Axis) starting at Midnight (0) and running through 11:00 PM or 23:00 (23).</p>
    </div>,
    second: <div>
        <p>This graph displays the average number of tests by hour across the date range. Tests used for Controls and Calibrations are also reflected in the graph if track loaded. </p>

        <p>Four parameters displayed on the graph can be adjusted: Start Date, End Date, Test Priority and Test.</p>

        <ul>
            <li>To adjust the date range, change the Start and/or the End Date.</li>
            <li>The data averages all tests (STAT and Non-STAT). To limit to just STAT or Non-STAT, adjust the Test Priority parameter. </li>
            <li>To see samples run with a specific assay, limit the test parameter to a specific test.  For example, to see samples with TSH testing, limit the Test parameter to just the TSH test. </li>

        </ul>

        <p>Note: After adjusting any of the parameters, click the Blue "View" to refresh the data.</p>

        <p>Additional functionality includes:</p>

        <ul>
            <li>To zoom in to a specific time frame, click within the chart area and drag across dates.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, including hour and number of tests hover the mouse over any hourly data point. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
            <li>To close the Details graph and return to the dashboard summary screen, Click "Close" next to the <FaQuestionCircle style={{ color: "#0056b3" }} />.</li>
        </ul>

    </div>
}

// Performance Dashboard 
export const ModulePerformanceFrHT = {
    title: "fr-Module Turn Around Time Help",
    first: <div>
        <p>This graph provides a summary of samples processed over time, on the current day, by the selected module. Easily spot trends and determine if there is an increase or decrease in samples processed by the selected module.</p>
    </div>,
    second: <div>
        <p>This graph displays the number of samples processed by the selected module, on the current day. If the same Sample ID is introduced to the same module multiple times, the sample is counted each time the Sample ID is processed at the module.</p>
        <p>This graph displays the number of samples processed by the selected module, on the current day. If the same Sample ID is introduced to the same module multiple times, the sample is counted each time the Sample ID is processed at the module.</p>
        <p>In addition to the current day workload, the 3-month daily average is displayed as a line on the graph. The 3-month average is the average for the last three months for the same day of week as the current day.</p>

        <p>The module displayed on the graph can be adjusted.</p>
        <ul>
            <li>To adjust the module displayed, change select the desired module from the dropdown menu located in the upper, right corner of the Module Performance header. </li>
        </ul>
        <p>Additional functionality includes:</p>
        <ul>
            <li>To zoom in to a specific time range, click within the chart area and drag across hours. The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, including the number of samples, hover the mouse over any data point on the graph.</li>
            <li>There is no link for "Details" perform additional analysis of this information.</li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const ModuleTurnAroundTimeFrHT = {
    title: "fr-Module Turn Around Time Help",
    first: <div>
        <p>The module turn around time is calculated from the time the sample enters the module until the time the sample leaves the module. The exception is the sample loading modules (Flex, Single Entry, Bulk Entry or Combo Rack Entry), where the turn around time is calculated from the time the bar code is read until the sample leaves the module. </p>
        <p>The Module Turn Around Time graph displays a box and whisker plot of the 95th Percentile, Minimum, Upper Quartile, Lower Quartile and the Median module turn around time for the selected module, on the current day. Additionally, a side-by-side box and whisker plot displays the 3-month daily average. The 3-month daily average is the average for the last three months for the same day of week as the current day.</p>
        <p>Observed differences in module turn around time are typically related to volume of samples. When unexpected increases are seen, it is recommended to investigate workload activity occurring at specific times.</p>
    </div>,
    second: <div>

        <p>The module displayed on the graph can be adjusted.</p>

        <ul>
            <li>To adjust the module displayed, change select the desired module from the dropdown menu located in the upper, right corner of the Module Performance header.</li>
            <li>To remove the Current Day or Past 3 Months series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across dates.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific turn around time data, hover the mouse over any data point. </li>
            <li>There is no link for "Details" perform additional analysis of this information.</li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const CentrifugationWorkloadFrHT = {
    title: "fr-Centrifuge Workload Help",
    first: <div>
        <p>This graph provides a summary of sample centrifugation over time, on the current day. Comparison to the Past 90 Days Centrifugation Workload graph provides the ability to spot trends and determine if there is an increase or decrease in samples centrifuged on the automation track versus sample centrifuged off the automation track.</p>
    </div>,
    second: <div>

        <p>This graph displays the sample centrifugation for the current day. The stacked bars delineate between samples centrifuged on the automation track and samples centrifuged off the track. Additionally, the percent centrifuged on the automation track is displayed as a line on the graph. This data is useful for assuring maximum utilization of the automation track.</p>
        <p>The percent centrifuged is impacted by the percent of samples that arrive at the performing lab already pre-centrifuged since these sample would not be expected to be centrifuged on the automation.</p>
        <p>Additional functionality includes:</p>

        <ul>
            <li>To remove the On Track, Off Track or the Percent Track Centrifuged series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across values.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>There is no link for "Details" perform additional analysis of this information.</li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const Past90DaysCentrifugationWorkloadFrHT = {
    title: "fr-Past 90 Days Centrifuge Workload Help",
    first: <div>
        <p>This graph provides a summary of sample centrifugation over time for the past 90 days. Data displayed is the average for the last 90 days of all samples processed on the same day of week as the current day. Comparison to the current day Centrifugation Workload graph provides the ability to spot trends and determine if there is an increase or decrease in samples centrifuged on the automation track versus sample centrifuged off the automation track.</p>
    </div>,
    second: <div>

        <p>This graph displays the average for the current day for sample centrifugation over the last 90 days. The stacked bars delineate between samples centrifuged on the automation track and samples centrifuged off the track. Additionally, the percent centrifuged on the automation track is displayed as a line on the graph. This data is useful for assuring maximum utilization of the automation track.</p>
        <p>The percent centrifuged is impacted by the percent of samples that arrive at the performing lab already pre-centrifuged since these sample would not be expected to be centrifuged on the automation.</p>
        <p>Additional functionality includes:</p>

        <ul>
            <li>To remove the On Track, Off Track or the Percent Track Centrifuged series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across values.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>There is no link for "Details" perform additional analysis of this information.</li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const SampleLoadingCharacteristicsFrHT = {
    title: "fr-Sample Loading Help",
    first: <div>
        <p>This graph provides a summary of sample loading over time, on the current day. Comparison to the Past 90 Days Sample Loading graph provides the ability to spot trends and determine if there is an increase or decrease in samples loaded on the automation track versus samples front loaded on an instrument.</p>
    </div>,
    second: <div>

        <p>This graph displays the sample loading for the current day. The stacked bars delineate between samples track loaded and samples front loaded on an instrument. In addition, the percent front loaded is displayed as a line on the graph.</p>
        <p>Front loading of instruments may impact the efficiency of the laboratory staff. Additionally, front loading of samples can impact the turn around time of samples processed on the automation track. Front loaded samples take priority over samples that are processed on the automation track. Continual front loading of samples delays sampling of samples waiting in the instrument bypasses leading to extended turn around times.</p>
        <p>Additional functionality includes:</p>

        <ul>
            <li>To remove the Track Loaded, Front Loaded or the Percent Front Loaded series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across values.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>To perform additional review and analysis of the sample loading, click the "Details" link to adjust parameters. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const SampleLoadingDetailsFrHT = {
    title: "fr-Sample Loading Detail Help",
    first: <div>
        <p>This graph provides a summary of sample loading over time for the selected date providing the ability to monitor the sample loading activity.</p>
    </div>,
    second: <div>

        <p>This graph displays the sample loading for the selected date. The stacked bars delineate between samples track loaded and samples front loaded on an instrument. In addition, the percent front loaded is displayed as a line on the graph.</p>
        <p>Front loading of instruments may impact the efficiency of the laboratory staff. Additionally, front loading of samples can impact the turn around time of samples processed on the automation track. Front loaded samples take priority over samples that are processed on the automation track. Continual front loading of samples delays sampling of samples waiting in the instrument bypasses leading to extended turn around times.</p>

        <p>The date range displayed on the graph can be adjusted.</p>

        <ul>
            <li>To adjust the date range, change the Start and/or the End Date.</li>
        </ul>
        <p>Note: After adjusting the date, click the Blue "View" to refresh the data.</p>

        <p>Additional functionality includes:</p>

        <ul>
            <li>To remove the Track Loaded, Front Loaded or the Percent Front Loaded series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across values.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
            <li>To close the Details graph and return to the dashboard summary screen, Click "Close" next to the <FaQuestionCircle style={{ color: "#0056b3" }} />.</li>
        </ul>
    </div>
}

export const Past90DaysSampleLoadingCharacteristicsFrHT = {
    title: "fr-Past 90 Days Sample Loading",
    first: <div>
        <p>This graph provides a summary of sample loading over time for the past 90 days. Data displayed is the average for the last 90 days of all samples loaded on the same day of week as the current day. Comparison to the current day Sample Loading graph provides the ability to spot trends and determine if there is an increase or decrease in samples loaded on the automation track versus samples front loaded on an instrument.</p>
    </div>,
    second: <div>

        <p>This graph displays the average for the current day for sample loading over the last 90 days. The stacked bars delineate between samples track loaded and samples front loaded on an instrument. In addition, the percent front loaded is displayed as a line on the graph.</p>
        <p>Front loading of instruments may impact the efficiency of the laboratory staff. Additionally, front loading of samples can impact the turn around time of samples processed on the automation track. Front loaded samples take priority over samples that are processed on the automation track. Continual front loading of samples delays sampling of samples waiting in the instrument bypasses leading to extended turn around times.</p>
        <p>Additional functionality includes:</p>

        <ul>
            <li>To remove the Track Loaded, Front Loaded or the Percent Front Loaded series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across values.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>To perform additional review and analysis of the sample loading, click the "Details" link to adjust parameters. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}


export const onTrackTATFrHT = {
    title: "fr-On Track Turn Around Time (Track to Result)",
    first: <div>
        <p>On track turn around time is calculated from the time the sample bar code is initially read on the automation track until the final test result is obtained. </p>
        <p>The On Track Turn Around Time graph displays a box and whisker plot of the 95th Percentile, Minimum, Upper Quartile, Lower Quartile and the Median on track turn around time for the current day. Additionally, a side-by-side box and whisker plot displays the 3-month daily average. The 3-month daily average is the average for the last three months for the same day of week as the current day.</p>
        <p>Observed differences in on track turn around time can have multiple causes including, but not limited to, sample/test volumes, assay incubation times, percent centrifuged on automation, front loading or dilution and repeat protocols/rates. When unexpected increases are seen, it is recommended to investigate workload activity occurring at specific times.</p>
    </div>,
    second: <div>
        <p>Additional functionality includes:</p>
        <ul>
            <li>To remove the Current Day or Past 3 Months series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across dates.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>To perform additional review and analysis of the sample loading, click the "Details" link to adjust parameters. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}

export const onTrackTATDetailsFrHT = {
    title: "fr- On Track Turn Around Time Details (Track to Result)",
    first: <div>
        <p> The On Track Turn Around Time Details graphs enables investigation of turn around time by providing the ability to filter the turn around time data by multiple parameters.</p>
        <p>The On Track Turn Around Time Details graph displays a box and whisker plot of the 95th Percentile, Minimum, Upper Quartile, Lower Quartile and the Median on track turn around time for the selected day and parameters. Additionally, a side-by-side box and whisker plot displays the 3-month daily average. The 3-month daily average is the average for the last three months for the same day of week as the selected day.</p>
        <p>Observed differences in on track turn around time can have multiple causes including, but not limited to, sample/test volumes, assay incubation times, percent centrifuged on automation, front loading or dilution and repeat protocols/rates. When unexpected increases are seen, it is recommended to investigate workload activity occurring at specific times.</p>

        <p>Multiple parameters displayed on the graph can be adjusted:</p>
        <ul>
            <li>Start Date: Start date for the data displayed on the graph. </li>
            <li>End Date: End date for the data displayed on the graph.</li>
            <li>Test Priority: Allows ability to display data in the graph based on a selected test priority. </li>
            <ul>
                <li>Options: All, Non-STAT or STAT</li>
                <li>Default: All</li>
            </ul>
            <li>Test: Allows ability to display graph based on a selected test.</li>
            <ul>
                <li>Default: All</li>
            </ul>
            <li>Technology: Allows ability to display graph based on selected test technology. Assays within different technology types have varying incubation times that affect the analytic time. Selecting an individual technology type allows for visualization of turn around times within groups of assays with expected similar analytic times.</li>
            <ul>
                <li>Options: All, MicroSlide, MicroTip or MicroWell</li>
                <li>Default: All</li>
            </ul>
            <li>Add-ons: Allows the ability to include or exclude add on turn around time data from the graph. Since turn around time data is calculated from the first time the sample bar code is initially read on the automation track until the final result is obtained, including add-ons in the cumulatively data can result in the appearance of high turn around times that are not related to the processing of the sample.</li>
            <ul>
                <li>Options: Include or Exclude</li>
                <li>Default: Include</li>
            </ul>

            <li>Re-runs: Allows the ability to include or exclude re-run turn around time data from the graph. Since turn around time data is calculated from the first time the sample bar code is initially read on the automation track until the final result is obtained, including re-runs in the cumulatively data can result in the appearance of high turn around times that are not related to the processing of the sample.</li>
            <ul>
                <li>Options: Include or Exclude</li>
                <li>Default: Include</li>
            </ul>

            <li>Centrifuge: Allows the ability to include or exclude turn around time data from the graph based on how the sample was centrifuged. Samples centrifuged on the automation track will include the sample centrifugation time while samples centrifuged off the automation track will not. This creates two distinct data set within the cumulative date. Filtering to a specific centrifugation type allows for visualization of turn around times within groups of similar samples.</li>
            <ul>
                <li>Options: All, On Track or Off Track</li>
                <li>Default: All</li>
            </ul>

            <li>Analyzer: Allows ability to display graph based on selected analyzer serial number</li>
            <ul>
                <li>Default: Include</li>
            </ul>
        </ul>
        <p>Note: After adjusting any of the parameters, click the Blue "View" to refresh the data.</p>

    </div>,
    second: <div>
        <p>Additional functionality includes:</p>
        <ul>
            <li>To remove the Selected Day or Past 3 Months series from the graph, click on the series in the legend below the graph. To reintroduce a series, click on the series name in the legend again.</li>
            <li>To zoom in to a specific time range, click within the chart area and drag across values.  The chart will refresh and the zoomed in data will appear. If the Y-Axis doesn't refresh, click and drag the zoom again. To go back to the summary, click the Reset Zoom button that appears on the right side of the chart, after you zoom in.</li>
            <li>To see the specific data, hover the mouse over any data point. </li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
            <li>To close the Details graph and return to the dashboard summary screen, Click "Close" next to the .<FaQuestionCircle style={{ color: "#0056b3" }} /></li>

        </ul>
    </div>
}

export const CumulativeTrackHourFrHT = {
    title: "fr-Cumulative Track to Result Turn Around Time",
    first: <div>
        <p>This histogram chart shows the number of tests completed within 10 minute time slots and the line graph shows the percentage of the total workload that is complete. This allows you to assess your production and your percentage completion rate. </p>
    </div>,
    second: <div>
        <ul>
            <li>The x-axis reflects the turn-around time in minutes </li>
            <li>The y-axis on the left is test volume </li>
            <li>The y-axis on the right is percentage completion</li>
        </ul>

        <p>Additional functionality includes:</p>
        <ul>
            <li>Holding your mouse over either the histogram or the line chart will display the data label and the percentage as appropriate.</li>
            <li>There is no link for "Details" perform additional analysis of this information.</li>
            <li>Click the <MdOutlineMenu /> to have the option to View in Full Screen, Print Chart, Download PNG image, Download JPEG image, Download PDF document or Download SVG vector image.</li>
        </ul>
    </div>
}