import React, { useState, useEffect, useRef } from 'react';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import { withTranslation } from "react-i18next";
import HighchartsMore from 'highcharts/highcharts-more';
import pareto from 'highcharts/modules/pareto'
import { months } from '../../assets/language.constants';

if (typeof Highcharts === 'object') {
    HighchartsExporting(Highcharts)
    HighchartsExportData(Highcharts)
    HighchartsMore(Highcharts);
    pareto(Highcharts);
}

const HighchartsWrapper = ({
    t,
    i18n: { language },
    chartOptions,
    chartTitle = "",
    propsUpdated,
    detailsChanged,
    apiData = []
}) => {

    const [options, setOptions] = useState({
        credits: false,
        ...chartOptions,
    })
    // Reference to handle graph's DOM functions.
    const highchartsRef = useRef();
    // creating a chartkey for unique DOM entry
    const [chartKey, setChartKey] = useState(Math.random())

    // function Mapping data with chartOptions
    const seriesDataMap = () => ({
        series: options.series.map((s, i) => ({
            ...s,
            data: apiData[i].data
        }))
    })
    // this lifecycle method runs when ever language changes
    useEffect(() => {
        Highcharts.setOptions({
            navigation: {
                buttonOptions: {
                    verticalAlign: 'top',
                    y: -60
                }
            },
            lang: {
                "downloadCSV": t("highcharts.export.downloadCSV"),
                "downloadJPEG": t("highcharts.export.downloadJPEG"),
                "downloadPDF": t("highcharts.export.downloadPDF"),
                "downloadPNG": t("highcharts.export.downloadPNG"),
                "downloadSVG": t("highcharts.export.downloadSVG"),
                "downloadXLS": t("highcharts.export.downloadXLS"),
                "printChart": t("highcharts.export.printChart"),
                "viewData": t("highcharts.export.viewData"),
                "viewFullscreen": t("highcharts.export.viewFullscreen"),
                "shortMonths": months[language],
                "thousandsSep": ""
            }
        })
        setOptions({
            ...chartOptions,
            credits: false,
            ...seriesDataMap()
        })
        setChartKey(Math.random())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    // this lifecycle method runs when ever chartTitle prop changes
    useEffect(() => {
        setOptions({
            ...chartOptions,
            credits: false,
            ...seriesDataMap()
        })
        setChartKey(Math.random())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartTitle])
    useEffect(() => {
        setOptions({
            ...chartOptions,
            credits: false,
            ...seriesDataMap()
        })
        setChartKey(Math.random())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propsUpdated])

    // this lifecycle method runs when ever details properties changes
    useEffect(() => {
        setOptions({
            ...chartOptions,
            credits: false,
            ...seriesDataMap()
        })
        setChartKey(Math.random())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailsChanged])

    // this lifecycle method runs when Highcharts starts load
    useEffect(() => {
        setOptions({
            ...chartOptions,
            credits: false,
            ...seriesDataMap()
        })
        setChartKey(Math.random())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (<HighchartsReact
        ref={highchartsRef}
        allowChartUpdate={true}
        //oneToOne={true}
        key={chartKey} // Highcharts key need to be refreshed on every rerender.
        highcharts={Highcharts}
        options={options}
    />)

}

export default withTranslation()(HighchartsWrapper)
