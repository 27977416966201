import React from 'react'
import Chart from "react-apexcharts";
import {Card, Spinner} from "react-bootstrap";
import {withTranslation} from "react-i18next";

const VasDashboardClient = require('vas-dashboard-client');
const defaultClient = VasDashboardClient.ApiClient.instance;
defaultClient.basePath = process.env.REACT_APP_VAS_DASHBOARD_API_BACKEND_HOSTNAME;

class VASBoxPlot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {
                    type: 'boxPlot',
                    data: [
                        {
                            x: 'TEST',
                            y: []
                        }
                    ]
                }
            ],
            options: {
                chart: {
                    type: 'boxPlot',
                    height: 350
                },
                title: {
                    text: 'Test Box Plot',
                    align: 'left'
                },
                plotOptions: {
                    boxPlot: {
                        colors: {
                            upper: '#26a0fc',
                            lower: '#fff'
                        }
                    }
                }
            },
            dataIsLoaded: false
        };
    }

    componentDidMount() {
        let series = this.state.series;
        let apiInstance = new VasDashboardClient.DefaultApi();
        apiInstance.getMetricValues(3, (error, data) => {
            if (error)
                console.log(error);
            else
                series[0]['data'][0]['y'] = data;

            this.setState({
                series: series,
                dataIsLoaded: true
            });
        });
    }

    render() {
        const { t } = this.props;
        return (
            this.state.dataIsLoaded
                ? <div className="row justify-content-center">
                        <Card style={{width: '50rem'}}>
                            <Card.Body>
                                <h5 className="text-dark mb-4">{t("values")} For { this.props.selectedOrgId } </h5>
                                <div className="mixed-chart">
                                    <Chart
                                        options={this.state.options}
                                        series={this.state.series}
                                        type="boxPlot"
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                :   <Spinner animation="border"/>
        );
    }
}

export default withTranslation()(VASBoxPlot);