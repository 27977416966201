import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from "react-i18next";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import { Input, FormGroup, Label } from "reactstrap";

const localeMap = {
    en: en,
    fr: fr
}
const InputWithButton = (props) => {
    return (
        <FormGroup >
            <Label>{props.label}</Label>
            <Input
                bsSize="md"
                {...props}
            />
        </FormGroup>
    );
};

const DatePickerComponent = ({ i18n: { language }, t, name, selected, onChange, label }) => {
    registerLocale("locale", localeMap[language])
    return (
        <DatePicker
            name={name}
            dateFormat="dd-MMM-yyyy"
            selected={selected}
            onChange={(date) => onChange({ target: { name, value: date } })}
            locale={"locale"}
            customInput={<InputWithButton label={label} />}
        />
    )
}

export default withTranslation()(DatePickerComponent)