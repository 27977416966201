import React from 'react'
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { withTranslation } from "react-i18next";
import VASFigure from '../VASFigure';
import moment from 'moment'
import detailsViewHoc from '../Utils/detailsView.hoc';

import {
    CentrifugationWorkloadEnHT,
    CumulativeTrackHourEnHT,
    ModulePerformanceEnHT,
    ModuleTurnAroundTimeEnHT,
    onTrackTATDetailsEnHT,
    onTrackTATEnHT,
    Past90DaysCentrifugationWorkloadEnHT,
    Past90DaysSampleLoadingCharacteristicsEnHT,
    SampleLoadingCharacteristicsEnHT,
    SampleLoadingDetailsEnHT,
    WelcomeEnHT
} from "../Utils/helpTextBody/en/body.component";
import {
    CentrifugationWorkloadFrHT,
    CumulativeTrackHourFrHT,
    ModulePerformanceFrHT,
    ModuleTurnAroundTimeFrHT,
    onTrackTATDetailsFrHT,
    onTrackTATFrHT,
    Past90DaysCentrifugationWorkloadFrHT,
    Past90DaysSampleLoadingCharacteristicsFrHT,
    SampleLoadingCharacteristicsFrHT,
    SampleLoadingDetailsFrHT, WelcomeFrHT
} from "../Utils/helpTextBody/fr/body.component";
import DetailsGraphComponent from '../Utils/DetailsGraph.component';
import SampleLoadingDetailsForm from '../Utils/detailsForm/SampleLoadingDetails.form';
import TurnAroundTimeDetailsForm from '../Utils/detailsForm/TurnAroundTimeDetails.form';
require("moment-duration-format");
const VasDashboardClient = require('vas-dashboard-client');

/* valucheck_results.trackevent where istrackevent = 1 and setid = 1 */
function calculateMean(arr) {
    let arrMean = arr.reduce((previousValue, currentValue) => {
        return previousValue + currentValue
    }, 0) / arr.length;
    return arrMean;
}

function calculateStandardDeviation(arr) {
    let meanValue = calculateMean(arr);

    let arrDeviation = arr.map((k) => {
        return (k - meanValue) ** 2;
    })

    let sumValue = arrDeviation.reduce((prev, current) => prev + current, 0);

    //let varianceValue = sumValue / arrDeviation.length;

    return Math.sqrt(sumValue / arrDeviation.length);
}

/* Set "today" as starting point */
const today = moment().format("DD-MMM-YYYY");
const oneYearAgo = moment().subtract(1, 'years').format("DD-MMM-YYYY");
const threeMonthsAgo = moment().subtract(3,'months').format("DD-MMM-YYYY");

const todayDate = moment().format("DD-MMM-YYYY")
const todayDay = moment().format("dddd")


const detailsEndDate = new Date();
const detailsStartDate = new Date();
const performanceStartDate = new Date();
detailsStartDate.setMonth(detailsEndDate.getMonth() - 3);
performanceStartDate.setMonth(detailsEndDate.getMonth() - 3);

//let moduleId = '90';

class PerformanceCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataIsLoaded: false,
            propsUpdated: true,
            detailsChanged: false,
            //productionCardDetailsFilter: {},
            sampleLoadingDetailsfilter: {},
            tatDetailsFilter: {},
            moduleValue: null,
            moduleName: null,
            //moduleId: '90',
            testSelectLabel: ""
        }
    }

    handleCloseClick = () => {
        this.setState({
            ...this.state,
            detailsOf: null
        })
        // Scroll.animateScroll.scrollTo(scrollBackTo);
    }

    // handleDetailsClick is managed using the detailsOf object 
    // and reused everywhere in VasFigureDetails and DetailsGraph Component
    //props =>
    //selectedOrgId,
    //vasId,
    //figureCode,
    //labelKey,
    //extraDetailsOptions,
    //detailsHelpTextObject

    handleDetailsClick = (props) => {
        this.setState({
            ...this.state,
            detailsOf: {
                ...props,
                figureCode: `${props.figureCode}-details`,

            },
            detailsChanged: !this.state.detailsChanged,
            // Reset Detail chart filters so that defaults are used for initial display
            productionCardDetailsFilter: {},
            sampleLoadingDetailsfilter: {},
            tatDetailsFilter: {}
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            vasId: nextProps.selectedVasId,
            dataIsLoaded: false // cause data to reload when Org/IM selection changes
        })
        // Module select options
        if (nextProps.selectedVasId != null) {
            // The next line gets an instance of the ApiClient (bearerAuth already set in Full.js)
            let apiInstance = new VasDashboardClient.DefaultApi();

            apiInstance.getModules(nextProps.selectedVasId, (error, data) => {
                if (error)
                    console.log("error: " + error);
                else {
                    this.setState({ moduleOptions: data });
                }
            });
            // Test (assay) select options for details filter
            apiInstance.getTestCodes(nextProps.selectedVasId, (error, data) => {
                if (error)
                    console.log("error: " + error);
                else {
                    this.setState({ testOptions: data });
                }
            });
            // Analyzer select options for details filter
            apiInstance.getAnalyzers(nextProps.selectedVasId, (error, data) => {
                if (error)
                    console.log("error: " + error);
                else {
                    this.setState({ analyzerOptions: data });
                }
            });
        }
    }

    componentDidMount() {
        this.setState({
            moduleOptions: [{
                moduleName: 'ES Flex Exit',
                moduleID: '90'
            }],
            moduleValue: '90',
            moduleName: 'ES Flex Exit',
            moduleId: '90',
            detailsOf: null,
            detailsChanged: !this.state.detailsChanged
        });
    }

    componentDidUpdate(prevProps) {
        // Make sure the orgId actually changed before doing anything
        if (this.props.selectedOrgId !== prevProps.selectedOrgId) {
            if (this.props.selectedOrgId) {
                this.setState({
                    dataIsLoaded: true,
                    detailsOf: null
                })
            }
            // this.fetchData(this.props.selectedOrgId);
        }
        // if any previous properties of graph changes graph reloads.
        if (prevProps !== this.props) {
            this.setState({
                propsUpdated: !this.state.propsUpdated
            })
        }
    }

    handleModuleSelect = (e) => {
        this.setState({
            moduleValue: e.target.value,
            moduleName: e.target.options[e.target.selectedIndex].label,
            moduleId: e.target.value
        })
    }

    handleSampleLoadingViewButtonClick = ({ options }) => {
        this.setState({
            sampleLoadingDetailsfilter: {
                apiOptions: { data: { options } },
            }
        })
    }
    handleTatViewButtonClick = ({ options }) => {
        this.setState({
            tatDetailsFilter: {
                apiOptions: { data: { options } },
            }
        })
    }

    // component is managed using HOC (detailsviewHoc) which consists of all the common logics and some state management
    // Each Card consists of VasFigure and a DetailsGraphComponent to manage summary and details graphs
    // If Details click is avaliable then handleDetails click Property  and extraDetailsOptions(i.e. details chartoptions) is required
    // else only extraOptions(i.e. chartoptions ) is required
    // Helptext is handled using helptextObject and detailsHelpTextobject

    render() {
        const { t, tatDetailsFilter, sampleLoadingDetailsfilter } = this.props;
        const scrollToSampleLoading = "detailsOf-sampleLoading"
        const scrollBackToSampleLoading = "sampleLoading"
        const scrollToTAT = "detailsOf-TAT"
        const scrollBackToTAT = "TAT"

        let tatFilterOptions = this.state.tatDetailsFilter.apiOptions || {
            //apiOptions: {
            data: {
                options: {
                    endDate: today,
                    startDate: threeMonthsAgo,
                    testPrioritySelect: "All",
                    testSelect: "All",
                    technologySelect: "All",
                    addOnsSelect: "Include",
                    reRunsSelect: "Include",
                    centrifugationSelect: "All",
                    analyzerSelect: "All"
                }
            }
            //}
        }

        let sampleLoadingFilterOptions = this.state.sampleLoadingDetailsfilter.apiOptions || {
            //apiOptions: {
            data: {
                options: {
                    endDate: today,
                    startDate: threeMonthsAgo,
                    testPrioritySelect: "All",
                    testSelect: "All"
                }
            }
            //}
        }

        return (
            this.state.dataIsLoaded ?
                <Row style={{ paddingBottom: "15px" }} >

                <Col md={{ size: 12 }}>
                    <Card className="card-default">
                        <CardHeader>
                            {t("card.vasPerformance")} | {this.props.selectedOrg.orgId}
                        </CardHeader>
                        <CardBody>

                            {/*TURN AROUND TIME*/}
                            <Card className="card-default">
                                <CardHeader className="card-default" id={scrollBackToTAT}>
                                    {t("highcharts.turnAroundTime")}
                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col lg={{ size: 6 }}>
                                            {/* 3.0 */}
                                            <VASFigure
                                                propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="current-turn-around-time"
                                                startDate={threeMonthsAgo} /* today - 3 months */
                                                endDate={today} /* today */
                                                chartTitle=""
                                                scrollTo={scrollToTAT}
                                                scrollBackTo={scrollBackToTAT}

                                                handleDetailsClick={this.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: t("highcharts.currentTurnAroundTime.containerLabel"), //t("highcharts.testsLoadedPerDay.containerLabel"),
                                                    yaxisTitle: t("highcharts.currentTurnAroundTime.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    navigation: {
                                                        buttonOptions: {
                                                            verticalAlign: 'top',
                                                            y: -30
                                                        }
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingBottom: 60,
                                                        spacingTop: 30,
                                                        events: {
                                                            load: function () {
                                                                let labels = new Array();

                                                                if (this.series[4].data.length > 0) {
                                                                    labels = this.series[4].data[0].y;
                                                                }

                                                                let ren = this.renderer;
                                                                ren.label(t("current") + ': ' + t("averagetat") + ': <b>' + labels[0] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("standard-deviation") + ': <b>' + labels[1] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("95th-percentile") + ': <b>' + labels[2] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("highcharts.totalTests") + ': <b>' + labels[6] + '</b>', 60, this.plotHeight + 115).css({}).add();
                                                                ren.label(t("past") + ':&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + t("averagetat") + ': <b>' + labels[3] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("standard-deviation") + ': <b>' + labels[4] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("95th-percentile") + ': <b>' + labels[5] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("highcharts.totalTests") + ': <b>' + labels[7] + '</b>', 60, this.plotHeight + 130).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.currentTurnAroundTime.xaxisTitle"), // localize
                                                        },
                                                    }],
                                                    yAxis: {
                                                        title: {
                                                            text: t("highcharts.currentTurnAroundTime.yaxisTitle"), // localize
                                                        },
                                                    },
                                                    tooltip: {
                                                        headerFormat: '',
                                                        footerFormat: '',
                                                        pointFormatter: function () {
                                                            let toolTipText =
                                                                t("highcharts.toolTip.hour") + ': ' + this.x + '<br/>' +
                                                                '<span>\u25CF</span> ' + this.series.name + '<br/>' +
                                                                '95%: <b>' + this.high + '</b><br/>' +
                                                                t("highcharts.upperQuartile") + ': <b>' + this.q3 + '</b><br/>' +
                                                                t("highcharts.median") + ': <b>' + this.median + '</b><br/>' +
                                                                t("highcharts.lowerQuartile") + ': <b>' + this.q1 + '</b><br/>' +
                                                                t("highcharts.minimum") + ': <b>' + this.low + '</b><br/>'

                                                            return toolTipText;
                                                        }
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: `${t("legends.CurrentFor")} ${todayDate} (${t("legends.today")})`, //"Current for 26-Jan-2022 (yesterday)", 
                                                            type: 'boxplot'
                                                        },
                                                        {
                                                            name: `${t("legends.Past3Months")} (${todayDay} ${t("legends.Average")})`,// "Past 3 Months (Wednesday Average)",
                                                            type: 'boxplot'
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                    ]
                                                }}
                                                /* 3.1 */
                                                extraDetailsOptions={{
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        spacingBottom: 60,
                                                        events: {
                                                            load: function () {
                                                                let aCurrentAvgTAT = new Array();
                                                                let currentAvg = 0;
                                                                let currentSD = 0;
                                                                let current95th = 0;
                                                                let labels = new Array();

                                                                if (this.series[0].data.length > 0) {
                                                                    this.series[0].data.forEach(element => {
                                                                        aCurrentAvgTAT.push(element.options.median);
                                                                        currentAvg += element.options.median;
                                                                    })
                                                                    currentAvg = currentAvg / this.series[0].data.length;
                                                                }
                                                                currentSD = calculateStandardDeviation(aCurrentAvgTAT);

                                                                if (this.series[1].data.length > 0) {
                                                                    this.series[1].data.forEach(element => {
                                                                        current95th += element.y;
                                                                    })
                                                                    current95th = current95th / this.series[1].data.length;
                                                                }

                                                                if (this.series[2].data.length > 0) {
                                                                    labels = this.series[2].data[0].y;
                                                                }

                                                                let ren = this.renderer;
                                                                ren.label("<b>" + t("detailsCard.startDate") + '</b>: ' + labels[0], 30, 0).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.endDate") + '</b>: ' + labels[1], 30, 15).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.testPriority") + '</b>: ' + labels[2], 250, 0).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.test") + '</b>: ' + labels[3], 250, 15).css({}).add();
                                                                ren.label(t("current") + ': ' + t("averagetat") + ': <b>' + labels[4] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("standard-deviation") + ': <b>' + labels[5] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("95th-percentile") + ': <b>' + labels[6] + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("highcharts.totalTests") + ': <b>' + labels[7] + '</b>', 30, this.plotHeight + 150).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    title: {
                                                        text: ""
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                    }],
                                                    yAxis: {
                                                        title: {
                                                            text: t("highcharts.tatDetails.yaxisTitle"), // localize
                                                        },
                                                    },
                                                    tooltip: {
                                                        headerFormat: '',
                                                        footerFormat: '',
                                                        pointFormatter: function () {
                                                            let toolTipText =
                                                                t("highcharts.toolTip.hour") + ': ' + this.x + '<br/>' +
                                                                '<span>\u25CF</span> ' + this.series.name + '<br/>' +
                                                                '95%: <b>' + this.high + '</b><br/>' +
                                                                t("highcharts.upperQuartile") + ': <b>' + this.q3 + '</b><br/>' +
                                                                t("highcharts.median") + ': <b>' + this.median + '</b><br/>' +
                                                                t("highcharts.lowerQuartile") + ': <b>' + this.q1 + '</b><br/>' +
                                                                t("highcharts.minimum") + ': <b>' + this.low + '</b><br/>'

                                                            return toolTipText;
                                                        },
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: `${t("legends.selectedTimeframe")}`,
                                                            type: 'boxplot'
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                    ]
                                                }}


                                                helpTextObject={{ en: onTrackTATEnHT, fr: onTrackTATFrHT }}
                                                detailsHelpTextObject={{ en: onTrackTATDetailsEnHT, fr: onTrackTATDetailsFrHT }}

                                            />

                                        </Col>
                                        <Col lg={{ size: 6 }}>
                                            {/* 4.0 */}
                                            <VASFigure
                                                propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="pareto-turn-around-time"
                                                startDate={today} /* today */
                                                endDate={today} /* today */
                                                chartTitle=""
                                                handleDetailsClick={this.handleDetailsClick}
                                                selectedModule=""
                                                scrollTo={scrollToTAT}
                                                scrollBackTo={scrollBackToTAT}
                                                labels={{
                                                    containerLabel: t("highcharts.paretoTurnAroundTime.containerLabel"), //t("highcharts.testsLoadedPerDay.containerLabel"),
                                                    yaxisTitle: t("highcharts.paretoTurnAroundTime.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    subtitle: {
                                                        text: todayDate
                                                    },
                                                    navigation: {
                                                        buttonOptions: {
                                                            verticalAlign: 'top',
                                                            y: -30
                                                        }
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 30,
                                                        events: {
                                                            load: function () {
                                                                let labels = new Array();

                                                                if (this.series[2].data.length > 0) {
                                                                    labels = this.series[2].data[0].y;
                                                                }

                                                                let ren = this.renderer;
                                                                ren.label(t("highcharts.totalTests") + ': <b>' + labels[0] + '</b>', 60, this.plotHeight + 155).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 5,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.paretoTurnAroundTime.xaxisTitle"), // localize
                                                        },
                                                    }],
                                                    yAxis: [{
                                                        title: {
                                                            text: t("highcharts.paretoTurnAroundTime.yaxisTitle"), // localize
                                                        },
                                                    },
                                                    {
                                                        title: {
                                                            text: '',
                                                        },
                                                        minPadding: 0,
                                                        maxPadding: 0,
                                                        max: 100,
                                                        min: 0,
                                                        opposite: true,
                                                        labels: {
                                                            format: "{value}%"
                                                        }
                                                    }],
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.timeslot") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            baseSeries: 1,
                                                            data: [],
                                                            name: t("legends.Percent"), // Percent,
                                                            type: "pareto",
                                                            yAxis: 1,
                                                            zIndex: 10,
                                                            tooltip: {
                                                                valueDecimals: 2,
                                                                valueSuffix: '%'
                                                            }
                                                        },
                                                        {
                                                            name: t("legends.NoOfTests"),//'Number of Tests',
                                                            type: 'column',
                                                            stacking: null
                                                        },
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }                                                    ]
                                                }}
                                                /* 4.1 pareto-turn-around-time */
                                                extraDetailsOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        spacingBottom: 60,
                                                        events: {
                                                            load: function () {
                                                                let labels = new Array();

                                                                if (this.series[2].data.length > 0) {
                                                                    labels = this.series[2].data[0].y;
                                                                }

                                                                let ren = this.renderer;
                                                                ren.label("<b>" + t("detailsCard.startDate") + '</b>: ' + labels[0], 30, 0).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.endDate") + '</b>: ' + labels[1], 30, 15).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.testPriority") + '</b>: ' + labels[2], 250, 0).css({}).add();
                                                                ren.label("<b>" + t("detailsCard.test") + '</b>: ' + labels[3], 250, 15).css({}).add();
                                                                ren.label(t("highcharts.totalTests") + ': <b>' + labels[4] + '</b>', 30, this.plotHeight + 140).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 5,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.paretoTurnAroundTime.xaxisTitle"), // localize
                                                        },
                                                    }],
                                                    yAxis: [{
                                                        title: {
                                                            text: t("highcharts.paretoTurnAroundTime.yaxisTitle"), // localize
                                                        }
                                                    },
                                                    {
                                                        title: {
                                                            text: '',
                                                        },
                                                        minPadding: 0,
                                                        maxPadding: 0,
                                                        max: 100,
                                                        min: 0,
                                                        opposite: true,
                                                        labels: {
                                                            format: "{value}%"
                                                        }
                                                    }],
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            baseSeries: 1,
                                                            data: [],
                                                            name: t("legends.Percent"), // Percent,
                                                            type: "pareto",
                                                            yAxis: 1,
                                                            zIndex: 10,
                                                            tooltip: {
                                                                valueDecimals: 2,
                                                                valueSuffix: '%'
                                                            }
                                                        },
                                                        {
                                                            name: t("legends.NoOfTests"),//'Number of Tests',
                                                            type: 'column',
                                                            stacking: null
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                    ]
                                                }}

                                                helpTextObject={{ en: CumulativeTrackHourEnHT, fr: CumulativeTrackHourFrHT }}
                                                detailsHelpTextObject={{ en: WelcomeEnHT, fr: WelcomeFrHT }}


                                            />

                                        </Col>
                                        <Col md={{ size: 12 }} className="px-0" id={scrollToTAT}>
                                            {/* 3.1 & 4.1 */}
                                            {
                                                this.state.detailsOf?.figureCode === "current-turn-around-time-details" &&
                                                <DetailsGraphComponent
                                                    detailsOf={this.state.detailsOf}
                                                    availableOrgs={this.props.availableOrgs}
                                                    vasId={this.props.selectedOrg.vasId}
                                                    startDate={threeMonthsAgo} /* today - 3 months */
                                                    endDate={today} /* today */
                                                    handleCloseClick={this.handleCloseClick}
                                                    scrollBackTo={scrollBackToTAT}

                                                        labelKey={this.state.detailsOf.labelKey}
                                                    labels={{
                                                        containerLabel: t(`highcharts.tatDetails.containerLabel`),
                                                        yaxisTitle: t(`highcharts.tatDetails.yaxisTitle`),
                                                    }}
                                                    detailsCardHeader={t("detailsCard.tatDetails")}
                                                        detailsChanged={this.state.detailsChanged}

                                                        detailsFormComponent={TurnAroundTimeDetailsForm}
                                                    //    detailsFormComponent={<TurnAroundTimeDetailsForm />}
                                                    //apiOptions={this.props.tatDetailsFilter.apiOptions}
                                                    apiOptions={tatFilterOptions}
                                                    handleViewButtonClick={this.handleTatViewButtonClick}
                                                        testOptions={this.state.testOptions}
                                                        analyzerOptions={this.state.analyzerOptions}
                                                />
                                            }
                                            {
                                                this.state.detailsOf?.figureCode === "pareto-turn-around-time-details" &&
                                                <DetailsGraphComponent
                                                    detailsOf={this.state.detailsOf}
                                                    availableOrgs={this.props.availableOrgs}
                                                    vasId={this.props.selectedOrg.vasId}
                                                    startDate={threeMonthsAgo} /* today - 3 months */
                                                    endDate={today} /* today */
                                                    handleCloseClick={this.handleCloseClick}
                                                    scrollBackTo={scrollBackToTAT}

                                                        labelKey={this.state.detailsOf.labelKey}
                                                    labels={{
                                                        //containerLabel: t(`highcharts.paretoTurnAroundTime.containerLabel`),
                                                        containerLabel: `${t("highcharts.paretoTurnAroundTime.containerLabel")} ${t("highcharts.details")}` ,
                                                        yaxisTitle: t(`highcharts.tatDetails.yaxisTitle`),
                                                    }}
                                                    detailsCardHeader={t("detailsCard.tatDetails")}
                                                        detailsChanged={this.state.detailsChanged}

                                                    detailsFormComponent={TurnAroundTimeDetailsForm}
//                                                    apiOptions={this.props.tatDetailsFilter.apiOptions}
                                                    apiOptions={tatFilterOptions}
                                                    handleViewButtonClick={this.handleTatViewButtonClick}
                                                        testOptions={this.state.testOptions}
                                                        analyzerOptions={this.state.analyzerOptions}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/*MODULE PERFORMANCE*/}
                            <Card className="card-default">
                                <CardHeader className="card-default">
                                    {t("highcharts.modulePerformance")}
                                    <span style={{
                                        float: 'right',
                                        fontWeight: 400,
                                    }}>
                                        {` Module : `}
                                        <select
                                            onChange={this.handleModuleSelect}
                                            value={this.state.moduleValue}
                                        >
                                            {/* <option>Select Module</option> */}
                                            {
                                                this.state.moduleOptions.map((v, i) =>
                                                    //<option key={i} value={v.value}>{v.name}</option>
                                                    <option key={i} value={v.moduleID}>{v.moduleName}</option>
                                                )
                                            }
                                        </select>
                                    </span>
                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col lg={{ size: 6 }}>
                                            {/* 5.0 */}
                                            <VASFigure
                                                    propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="module-volume"
                                                moduleID={this.state.moduleId}
                                                startDate={threeMonthsAgo} /* today - 3 months */
                                                endDate={today} /* today */
                                                chartTitle={`Module: ${this.state.moduleName}`}
                                                // handleDetailsClick={this.props.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: t("highcharts.moduleVolume.containerLabel"),
                                                    yaxisTitle: t("highcharts.moduleVolume.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: this.state.moduleName && `${t("highcharts.moduleVolume.title")}: ${this.state.moduleName}`
                                                    },
                                                    navigation: {
                                                        buttonOptions: {
                                                            verticalAlign: 'top',
                                                            y: -30
                                                        }
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingBottom: 60,
                                                        spacingTop: 30,
                                                        events: {
                                                            load: function () {
                                                                let currentCount = 0;
                                                                let pastCount = 0;
                                                                if (this.series[0].data.length > 0) {
                                                                    this.series[0].data.forEach(element => {
                                                                        currentCount += element.y;
                                                                    })
                                                                }
                                                                if (this.series[1].data.length > 0) {
                                                                    this.series[1].data.forEach(element => {
                                                                        pastCount += element.y;
                                                                    })
                                                                }
                                                                let ren = this.renderer;
                                                                ren.label(t("total-samples-current-day") + ': <b>' + currentCount.toLocaleString('en') + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("total-samples-past-3-months") + ': <b>' + pastCount.toLocaleString('en') + '</b>', 60, this.plotHeight + 185).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.moduleVolume.xaxisTitle"), // localize
                                                        },
                                                    }],
                                                    yAxis: {
                                                        title: {
                                                            text: t("highcharts.moduleVolume.yaxisTitle"), // localize
                                                        },
                                                    },
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: `${t("legends.CurrentFor")} ${todayDate} (${t("legends.today")})`, //"Current for 26-Jan-2022 (yesterday)", 
                                                            stacking: null,
                                                            type: "column",
                                                        },
                                                        {
                                                            name: `${t("legends.Past3Months")} (${todayDay} ${t("legends.Average")})`,// "Past 3 Months (Wednesday Average)",
                                                            type: "spline"
                                                        }

                                                    ]
                                                }}


                                                helpTextObject={{ en: ModulePerformanceEnHT, fr: ModulePerformanceFrHT }}
                                                detailsHelpTextObject={{ en: WelcomeEnHT, fr: WelcomeFrHT }}

                                            />
                                        </Col>

                                        <Col lg={{ size: 6 }}>
                                            {/* 6.0 */}
                                            <VASFigure
                                                propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="module-turnaround-time"
                                                moduleID={this.state.moduleId}
                                                startDate={threeMonthsAgo} /* today - 3 months */
                                                endDate={today} /* today */
                                                chartTitle={`${t("highcharts.moduleTurnAroundTime.title")}: ${this.state.moduleName}`}
                                                // handleDetailsClick={this.props.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: t("highcharts.moduleTurnAroundTime.containerLabel"), //t("highcharts.testsLoadedPerDay.containerLabel"),
                                                    yaxisTitle: t("highcharts.moduleTurnAroundTime.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: this.state.moduleName && `${t("highcharts.moduleTurnAroundTime.title")}: ${this.state.moduleName}`
                                                    },
                                                    navigation: {
                                                        buttonOptions: {
                                                            verticalAlign: 'top',
                                                            y: -30
                                                        }
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingBottom: 60,
                                                        spacingTop: 30,
                                                        events: {
                                                            load: function () {
                                                                let aCurrentAvgTAT = new Array();
                                                                let currentAvg = 0;
                                                                let currentSD = 0;
                                                                let current95th = 0;
                                                                let aPastAvgTAT = new Array();
                                                                let pastAvg = 0;
                                                                let pastSD = 0;
                                                                let past95th = 0;

                                                                if (this.series[0].data.length > 0) {
                                                                    this.series[0].data.forEach(element => {
                                                                        aCurrentAvgTAT.push(element.options.median);
                                                                        currentAvg += element.options.median;
                                                                    })
                                                                    currentAvg = currentAvg / this.series[0].data.length;
                                                                }
                                                                currentSD = calculateStandardDeviation(aCurrentAvgTAT);

                                                                if (this.series[1].data.length > 0) {
                                                                    this.series[1].data.forEach(element => {
                                                                        aPastAvgTAT.push(element.options.median);
                                                                        pastAvg += element.options.median;
                                                                    })
                                                                    pastAvg = pastAvg / this.series[1].data.length;
                                                                }
                                                                pastSD = calculateStandardDeviation(aPastAvgTAT);

                                                                if (this.series[2].data.length > 0) {
                                                                    this.series[2].data.forEach(element => {
                                                                        current95th += element.y;
                                                                    })
                                                                    current95th = current95th / this.series[2].data.length;
                                                                }
                                                                if (this.series[3].data.length > 0) {
                                                                    this.series[3].data.forEach(element => {
                                                                        past95th += element.y;
                                                                    })
                                                                    past95th = past95th / this.series[3].data.length;
                                                                }

                                                                let ren = this.renderer;
                                                                ren.label(t("current") + ': ' + t("averagetat") + ': <b>' + moment.duration(currentAvg).format('H:mm:ss', { trim: false }) + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("standard-deviation") + ': <b>' + moment.duration(currentSD).format('H:mm:ss', { trim: false }) + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("95th-percentile") + ': <b>' + moment.duration(current95th).format('H:mm:ss', { trim: false }) + '</b>&nbsp;&nbsp;&nbsp;&nbsp;', 60, this.plotHeight + 185).css({}).add();
                                                                ren.label(t("past") + ':&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + t("averagetat") + ': <b>' + moment.duration(pastAvg).format('H:mm:ss', { trim: false }) + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("standard-deviation") + ': <b>' + moment.duration(pastSD).format('H:mm:ss', { trim: false }) + '</b>&nbsp;&nbsp;&nbsp;&nbsp;' + t("95th-percentile") + ': <b>' + moment.duration(past95th).format('H:mm:ss', { trim: false }) + '</b>&nbsp;&nbsp;&nbsp;&nbsp;', 60, this.plotHeight + 200).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.moduleTurnAroundTime.xaxisTitle"), // localize
                                                        },
                                                    }],
                                                    yAxis: {
                                                        title: {
                                                            text: t("highcharts.moduleTurnAroundTime.yaxisTitle"), // localize
                                                        },
                                                        type: 'datetime',
                                                        dateTimeLabelFormats: {
                                                            second: '%H:%M:%S',
                                                            minute: '%H:%M:%S',
                                                            hour: '%H:%M:%S',
                                                            day: '%H:%M:%S',
                                                            week: '%H:%M:%S',
                                                            month: '%H:%M:%S',
                                                            year: '%H:%M:%S'
                                                         }
                                                    },
                                                    tooltip: {
                                                        pointFormatter: function () {
                                                            let toolTipText =
                                                                t("highcharts.toolTip.hour") + ': ' + this.x + '<br/>' +
                                                                '<span>\u25CF</span> ' + this.series.name + '<br/>' +
                                                                '95%: <b>' + moment.duration(this.high).format('H:mm:ss', { trim: false }) + '</b><br/>' +
                                                                t("highcharts.upperQuartile") + ': <b>' + moment.duration(this.q3).format('H:mm:ss', { trim: false }) + '</b><br/>' +
                                                                t("highcharts.median") + ': <b>' + moment.duration(this.median).format('H:mm:ss', { trim: false }) + '</b><br/>' +
                                                                t("highcharts.lowerQuartile") + ': <b>' + moment.duration(this.q1).format('H:mm:ss', { trim: false }) + '</b><br/>' +
                                                            t("highcharts.minimum") + ': <b>' + moment.duration(this.low).format('H:mm:ss', { trim: false }) + '</b><br/>'

                                                            return toolTipText;
                                                        }
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: `${t("legends.CurrentFor")} ${todayDate} (${t("legends.today")})`, //'Current for 26-Jan-2022 (yesterday)',
                                                            type: 'boxplot'
                                                        },
                                                        {
                                                            name: `${t("legends.Past3Months")} ${todayDay} ${t("legends.Average")}`,//'Past 3 Months (Wednesday Average)',
                                                            type: 'boxplot'
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            visible: false,
                                                            showInLegend: false
                                                        }
                                                    ]
                                                }}

                                                helpTextObject={{ en: ModuleTurnAroundTimeEnHT, fr: ModuleTurnAroundTimeFrHT }}
                                                detailsHelpTextObject={{ en: WelcomeEnHT, fr: WelcomeFrHT }}

                                            />

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/*CENTRIFUGATION*/}
                            <Card className="card-default">
                                <CardHeader className="card-default">
                                    {t("highcharts.centrifugation")}
                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col lg={{ size: 6 }}>
                                            {/* 7.0 */}
                                            <VASFigure
                                                propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="current-centrifugation-workload"
                                                startDate={today} /* today */
                                                endDate={today} /* today */
                                                chartTitle=""
                                                // handleDetailsClick={this.props.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: `${t("highcharts.currentCentrifugationWorkload.containerLabel")} ${todayDate} (${t("highcharts.currentCentrifugationWorkload.today")})`, //t("highcharts.testsLoadedPerDay.containerLabel"),
                                                    yaxisTitle: t("highcharts.currentCentrifugationWorkload.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        spacingBottom: 60,
                                                        events: {
                                                            load: function () {
                                                                let totalTrack = 0;
                                                                let totalSamples = 0;
                                                                let totalPctCent = 0;
                                                                // count TC for total # TC. count TC + OC for total # samples. divide TC by Total
                                                                if (this.series[0].data.length > 0 && this.series[1].data.length > 0) {
                                                                    for (let i = 0; i < this.series[0].data.length; i++) {
                                                                        totalSamples = totalSamples + this.series[0].data[i].y + this.series[1].data[i].y;
                                                                        totalTrack += this.series[0].data[i].y;
                                                                    }
                                                                    totalPctCent = ((totalSamples > 0) ? totalTrack / totalSamples : 0) * 100;
                                                                }
                                                                let ren = this.renderer;
                                                                ren.label(t("total-percent-track-centrifuged") + ': <b>' + Math.round(totalPctCent) + '%</b>', 60, this.plotHeight + 160).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.currentCentrifugationWorkload.xaxisTitle"),
                                                        },
                                                        dateTimeLabelFormats: {
                                                            day: '%e-%b',
                                                            week: '%b %e',
                                                            month: '%b'
                                                        }
                                                    }],
                                                    yAxis: [{
                                                        title: {
                                                            text: t("legends.PercentTrackCentrifuged"),
                                                        },
                                                        opposite: true,
                                                        min: 0,
                                                        max: 100,
                                                        endOnTick: false,
                                                        maxPadding: 0,
                                                    }, {
                                                            title: {
                                                                text: t("highcharts.currentCentrifugationWorkload.yaxisTitle"),
                                                            }
                                                        }],
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: t("legends.OnTrack"), //'On Track',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.OffTrack"), //'Off Track',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.PercentTrackCentrifuged"), //'Percent Track Centrifuged',
                                                            type: 'spline'
                                                        }
                                                    ]
                                                }}


                                                helpTextObject={{ en: CentrifugationWorkloadEnHT, fr: CentrifugationWorkloadFrHT }}
                                                detailsHelpTextObject={{ en: WelcomeEnHT, fr: WelcomeFrHT }}

                                            />
                                        </Col>

                                        <Col lg={{ size: 6 }}>
                                            {/* 8.0 */}
                                            <VASFigure
                                                    propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="past-centrifugation-workload"
                                                startDate={threeMonthsAgo} /* today - 3 months */
                                                endDate={today} /* today */
                                                chartTitle=""
                                                // handleDetailsClick={this.props.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: `${t("highcharts.pastCentrifugationWorkload.containerLabel")} (${todayDay} ${t("highcharts.pastCentrifugationWorkload.average")})`, //t("highcharts.testsLoadedPerDay.containerLabel"),
                                                    yaxisTitle: t("highcharts.pastCentrifugationWorkload.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        spacingBottom: 60,
                                                        events: {
                                                            load: function () {
                                                                let totalTrack = 0;
                                                                let totalSamples = 0;
                                                                let totalPctCent = 0;
                                                                // count TC for total # TC. count TC + OC for total # samples. divide TC by Total
                                                                if (this.series[0].data.length > 0 && this.series[1].data.length > 0) {
                                                                    for (let i = 0; i < this.series[0].data.length; i++) {
                                                                        totalSamples = totalSamples + this.series[0].data[i].y + this.series[1].data[i].y;
                                                                        totalTrack += this.series[0].data[i].y;
                                                                    }
                                                                    totalPctCent = ((totalSamples > 0) ? totalTrack / totalSamples : 0) * 100;
                                                                }
                                                                let ren = this.renderer;
                                                                ren.label(t("total-percent-track-centrifuged") + ': <b>' + Math.round(totalPctCent) + '%</b>', 60, this.plotHeight + 160).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.pastCentrifugationWorkload.xaxisTitle"), 
                                                        },
                                                    }],
                                                    yAxis: [{
                                                        title: {
                                                            text: t("legends.PercentTrackCentrifuged"), 
                                                        },
                                                        opposite: true,
                                                        min: 0,
                                                        endOnTick: false,
                                                        maxPadding: 0,
                                                        max: 100,
                                                    }, {
                                                        title: {
                                                            text: t("highcharts.pastCentrifugationWorkload.yaxisTitle"),
                                                        },
                                                        min: 0,
                                                        }],
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: t("legends.OnTrack"),
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.OffTrack"),
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.PercentTrackCentrifuged"),
                                                            type: 'spline'
                                                        }
                                                    ]
                                                }}


                                                helpTextObject={{ en: Past90DaysCentrifugationWorkloadEnHT, fr: Past90DaysCentrifugationWorkloadFrHT }}
                                                detailsHelpTextObject={{ en: WelcomeEnHT, fr: WelcomeFrHT }}
                                            />

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/*SAMPLE LOADING*/}
                            <Card className="card-default">
                                <CardHeader className="card-default" id={scrollBackToSampleLoading}>
                                    {t("highcharts.sampleLoading")}
                                </CardHeader>

                                <CardBody>
                                    <Row>
                                        <Col lg={{ size: 6 }}>
                                            {/* 9.0 */}
                                            <VASFigure
                                                    propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="current-sample-loading"
                                                startDate={today} /* today */
                                                endDate={today} /* today */
                                                scrollTo={scrollToSampleLoading}
                                                chartTitle=""
                                                handleDetailsClick={this.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: `${t("highcharts.currentSampleLoading.containerLabel")} ${todayDate} (${t("highcharts.currentSampleLoading.today")})`, //t("highcharts.testsLoadedPerDay.containerLabel"),
                                                    yaxisTitle: t("highcharts.currentSampleLoading.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        spacingBottom: 60,
                                                        events: {
                                                            load: function () {
                                                                let totalFront = 0;
                                                                let totalSamples = 0;
                                                                let totalPctFront = 0;
                                                                // count FL for total # FL. count TL + FL for total # samples. divide FL by Total
                                                                if (this.series[0].data.length > 0 && this.series[1].data.length > 0) {
                                                                    for (let i = 0; i < this.series[0].data.length; i++) {
                                                                        totalSamples = totalSamples + this.series[0].data[i].y + this.series[1].data[i].y;
                                                                        totalFront += this.series[1].data[i].y;
                                                                    }
                                                                    totalPctFront = ((totalSamples > 0) ? totalFront / totalSamples : 0) * 100;
                                                                }
                                                                let ren = this.renderer;
                                                                ren.label(t("Total Percent Front Loaded") + ': <b>' + Math.round(totalPctFront) + '%</b>', 60, this.plotHeight + 160).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.currentSampleLoading.xaxisTitle"), // localize
                                                        },
                                                    }],
                                                    yAxis: [{
                                                        title: {
                                                            text: t("legends.PercentFrontLoaded")
                                                        },
                                                        min: 0,
                                                        max: 100,
                                                        endOnTick: false,
                                                        maxPadding: 0,
                                                        opposite: true
                                                        }, {
                                                        title: {
                                                            text: t("highcharts.currentSampleLoading.yaxisTitle"), // localize
                                                        },
                                                        }],
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: t("legends.TrackLoaded"), //'Track Loaded',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.FrontLoaded"), //'Front Loaded',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.PercentFrontLoaded"), //'Percent Front Loaded', 
                                                            type: 'spline'
                                                        }
                                                    ]
                                                }}
                                                extraDetailsOptions={{
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        events: {
                                                            load: function () {
                                                                let labels = new Array();
                                                                let totalFront = 0;
                                                                let totalSamples = 0;
                                                                let totalPctFront = 0;
                                                                // count FL for total # FL. count TL + FL for total # samples. divide FL by Total
                                                                if (this.series[0].data.length > 0 && this.series[1].data.length > 0) {
                                                                    for (let i = 0; i < this.series[0].data.length; i++) {
                                                                        totalSamples = totalSamples + this.series[0].data[i].y + this.series[1].data[i].y;
                                                                        totalFront += this.series[1].data[i].y;
                                                                    }
                                                                    totalPctFront = ((totalSamples > 0) ? totalFront / totalSamples : 0) * 100;
                                                                }
                                                                if (this.series[3].data.length > 0) {
                                                                    labels = this.series[3].data[0].y;
                                                                }
                                                                let ren = this.renderer;
                                                                ren.label(t("detailsCard.date") + ': ' + labels[0], 30, -5).css({}).add();
                                                                ren.label(t("Total Percent Front Loaded") + ': <b>' + Math.round(totalPctFront) + '</b>', 30, 10).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    title: {
                                                        text: null
                                                    },
                                                    xAxis: {
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        tickInterval: 1,
                                                        title: {
                                                            text: t("highcharts.currentSampleLoading.xaxisTitle")
                                                        }
                                                    },
                                                    yAxis: [{
                                                        title: {
                                                            text: t("legends.PercentFrontLoaded")
                                                        },
                                                        min: 0,
                                                        max: 100,
                                                        endOnTick: false,
                                                        maxPadding: 0,
                                                        opposite: true
                                                    }, {
                                                        title: {
                                                            text: t("highcharts.currentSampleLoading.yaxisTitle"), // localize
                                                        },
                                                    }],
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: t("legends.TrackLoaded"), //'Track Loaded',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.FrontLoaded"), //'Front Loaded',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.PercentFrontLoaded"), //'Percent Front Loaded', 
                                                            type: 'spline'
                                                        }
                                                        ,
                                                        {
                                                            name: '',
                                                            showInLegend: false
                                                        }
                                                    ]
                                                }}

                                                helpTextObject={{ en: SampleLoadingCharacteristicsEnHT, fr: SampleLoadingCharacteristicsFrHT }}
                                                detailsHelpTextObject={{ en: SampleLoadingDetailsEnHT, fr: SampleLoadingDetailsFrHT }}

                                            />
                                        </Col>

                                        <Col lg={{ size: 6 }}>
                                            {/* 10.0 */}
                                            <VASFigure
                                                    propsUpdated={this.state.propsUpdated}
                                                selectedOrgId={this.props.selectedOrgId}
                                                vasId={this.props.selectedOrg.vasId}
                                                figureCode="past-sample-loading"
                                                startDate={threeMonthsAgo} /* today - 3 months */
                                                endDate={today} /* today */
                                                chartTitle=""
                                                // handleDetailsClick={this.props.handleDetailsClick}
                                                selectedModule=""
                                                labels={{
                                                    containerLabel: `${t("highcharts.pastSampleLoading.containerLabel")} (${todayDay} ${t("highcharts.pastSampleLoading.average")})`,
                                                    yaxisTitle: t("highcharts.pastSampleLoading.yaxisTitle"),
                                                }}
                                                extraOptions={{
                                                    title: {
                                                        text: ""
                                                    },
                                                    chart: {
                                                        zoomType: "xy",
                                                        spacingTop: 60,
                                                        spacingBottom: 60,
                                                        events: {
                                                            load: function () {
                                                                let totalFront = 0;
                                                                let totalSamples = 0;
                                                                let totalPctFront = 0;
                                                                // count FL for total # FL. count TL + FL for total # samples. divide FL by Total
                                                                if (this.series[0].data.length > 0 && this.series[1].data.length > 0) {
                                                                    for (let i = 0; i < this.series[0].data.length; i++) {
                                                                        totalSamples = totalSamples + this.series[0].data[i].y + this.series[1].data[i].y;
                                                                        totalFront += this.series[1].data[i].y;
                                                                    }
                                                                    totalPctFront = ((totalSamples > 0) ? totalFront / totalSamples : 0) * 100;
                                                                }
                                                                let ren = this.renderer;
                                                                ren.label(t("Total Percent Front Loaded") + ': <b>' + Math.round(totalPctFront) + '%</b>', 60, this.plotHeight + 160).css({}).add();
                                                            }
                                                        },
                                                    },
                                                    xAxis: [{
                                                        tickInterval: 1,
                                                        endOnTick: false,
                                                        startOnTick: false,
                                                        title: {
                                                            text: t("highcharts.pastSampleLoading.xaxisTitle"), 
                                                        },
                                                    }],
                                                    yAxis: [{
                                                        title: {
                                                            text: t("legends.PercentFrontLoaded"), 
                                                        },
                                                        min: 0,
                                                        max: 100,
                                                        endOnTick: false,
                                                        maxPadding: 0,
                                                        opposite: true,
                                                    }, {
                                                        title: {
                                                            text: t("highcharts.pastSampleLoading.yaxisTitle"), 
                                                        }
                                                        }],
                                                    tooltip: {
                                                        headerFormat: '<span style="font-size:12px;font-weight:bold;">' + t("highcharts.toolTip.hour") + ':&nbsp;{point.key}</span><table>',
                                                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                                        footerFormat: '</table>',
                                                        shared: false,
                                                        useHTML: true
                                                    },
                                                    plotOptions: {
                                                        column: {
                                                            cropThreshold: 100000  // Fix for disappearing small values issue
                                                        },
                                                        series: {
                                                            marker: {
                                                                enabled: false
                                                            }
                                                        }
                                                    },
                                                    legend: {
                                                        // verticalAlign: 'top',
                                                    },
                                                    series: [
                                                        {
                                                            name: t("legends.TrackLoaded"), //'Track Loaded',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.FrontLoaded"), //'Front Loaded',
                                                            type: 'column',
                                                            stacking: 'normal',
                                                            yAxis: 1
                                                        },
                                                        {
                                                            name: t("legends.PercentFrontLoaded"), //'Percent Front Loaded', 
                                                            type: 'spline'
                                                        }
                                                    ]
                                                }}

                                                helpTextObject={{ en: Past90DaysSampleLoadingCharacteristicsEnHT, fr: Past90DaysSampleLoadingCharacteristicsFrHT }}
                                                detailsHelpTextObject={{ en: WelcomeEnHT, fr: WelcomeFrHT }}

                                            />

                                        </Col>
                                        <Col md={{ size: 12 }} className="px-0" id={scrollToSampleLoading}>
                                            {
                                                this.state.detailsOf?.figureCode === "current-sample-loading-details" &&
                                                <DetailsGraphComponent
                                                        detailsOf={this.state.detailsOf}
                                                        availableOrgs={this.props.availableOrgs}
                                                        vasId={this.props.selectedOrg.vasId}
                                                        startDate={today} /* today */
                                                        endDate={today} /* today */
                                                        handleCloseClick={this.handleCloseClick}
                                                        scrollBackTo={scrollBackToSampleLoading}

                                                        labelKey={this.state.detailsOf.labelKey}
                                                        labels={{
                                                            containerLabel: t(`highcharts.sampleLoadingDetails.containerLabel`),
                                                            yaxisTitle: t(`highcharts.sampleLoadingDetails.yaxisTitle`),
                                                        }}
                                                        detailsCardHeader={t("detailsCard.sampleLoadingDetails")}
                                                        detailsChanged={this.state.detailsChanged}

                                                        detailsFormComponent={SampleLoadingDetailsForm}
                                                        //apiOptions={this.props.sampleLoadingDetailsfilter.apiOptions}
                                                        apiOptions={sampleLoadingFilterOptions}
                                                        handleViewButtonClick={this.handleSampleLoadingViewButtonClick}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                        </CardBody>
                    </Card>
                </Col>
                </Row>
                :null
        );
    }
}

//export default detailsViewHoc(withTranslation()(PerformanceCard));
export default withTranslation()(PerformanceCard);
