import React from 'react';
import { Link } from "react-router-dom";
import $ from 'jquery'; 
import * as Scroll from "react-scroll";

import footerMenuApiCallService from '../../services/uiFooterApiCall.service';

import {
    Nav,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    NavItem
} from 'reactstrap';

import i18n from "i18next";
import { withTranslation } from "react-i18next";

const mobileMenuToggle=()=>{
  // close sub menu  mobile layout if sub menu is visible
  if($(".navbar-collapse div").is(":visible")){
    $(".navbar-collapse").collapse('hide');
  } 
  // close sub menu  mobile layout if sub menu is visible
}

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            name: "Kyle Thomas",
            language:"en",
            footerMenus: []
        };
    }

    async componentDidMount() {
        if (sessionStorage.getItem("encodedUserToken") != null)
            this.setState({
                loggedIn: true
            });
        const uiFooterMenus = await footerMenuApiCallService();
        this.setState({footerMenus:uiFooterMenus})
    }

    changeLang = (e) => {
        i18n.changeLanguage(e.target.value);
        Scroll.animateScroll.scrollToTop();
        this.setState({language:e.target.value});
    }

    logout() {
        sessionStorage.removeItem('encodedUserToken');
        window.location.href = '/';
    }

    sec2SubMenusToggle=(id)=>{
        //$("#"+id+"subm").slideToggle();
        if($("#"+id+"subm").is(":visible")){
            $("#"+id+"subm").hide();
            $("#"+id+"subC").removeClass('fa-caret-up');
            $("#"+id+"subC").addClass('fa-caret-down');
        } else {
            $("#"+id+"subm").show();
            $("#"+id+"subC").removeClass('fa-caret-down');
            $("#"+id+"subC").addClass('fa-caret-up');
        }
    }

    render() {
        const { t } = this.props;
        const social_class = { 
            twitter:"fab fa-twitter",
            facebook:"fab fa-facebook-f",
            linkedin:"fab fa-linkedin-in",
            instagram:"fab fa-instagram"
         };
        return (
                <>

<footer id="site-footer"   className="footer-container">

<div className="container">
{/* MENUS HORIZONTAL ROW 1 MENUS HORIZONTAL ROW 1 MENUS HORIZONTAL ROW 1 MENUS HORIZONTAL ROW 1  */}
  <div className="row">
    <div className=" d-sm-block col-sm-12 col-md-9 ">
        <div className="footer__quick-links footer__quick-links--primary">
        <ul className="list-unstyled" >
            {/* {this.state.footerMenus.map((gp,i) => {
                // if (gp?.position == 1   ) {
                // return(
                //     <div>{ JSON.stringify(gp.menus) }</div>
                // )
                // }
                
                        //  if (gp?.position == 1   ) {
                        //      gp?.menus.map((gpm,j) => {
                        //          return(
                        //              <li  key={'g1_'+j}>
                        //                  <Link   key={'g1l_'+j} 
                        //                      to={gpm?.target_url}  >
                        //                      {t(gpm?.i18n_menu_key)}
                        //                  </Link>
                        //              </li>
                        //          )
                        //      }
                        //      )
                        //  }
                          

            }
            )} */}
            {this.state?.footerMenus.find( (gp,i) => 
                gp?.position === 1 )?.menus.map((mn,j) => {
                    return(
                        <li  key={'g1_'+j}>
                            <a  key={'g1l_'+j}  href={mn?.target_url} >{t(mn?.i18n_menu_key)}</a>
                        </li>
                    )
                })
            }
            {/* <li>
                <a id="ctl00_Footer1_PrimaryPosition01" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/contact-us">Contact Us</a>
            </li>
            <li>
                <a id="ctl00_Footer1_PrimaryPosition02" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/careers">Careers</a>
            </li>
            <li>
                <a id="ctl00_Footer1_PrimaryPosition03" href="https://orthoplusqa.orthoclinicaldiagnostics.com">Ortho Plus</a>
            </li>
            <li>
                <a id="ctl00_Footer1_PrimaryPosition04" href="https://techdocs.orthoclinicaldiagnostics.com/TechDocs/TechDocSearch.aspx?tID=0">Technical Documents</a>
            </li>
            <li>
                <a id="ctl00_Footer1_PrimaryPosition05"></a>
            </li> */}
            </ul>
        </div>
    </div>
    <div className=" d-none d-md-block col-md-3">
        <div className="footer__quick-links footer__quick-links--primary footer__quick-links--social ">
            <ul className=" justify-content-end  ">
            {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 2 )?.menus.map((mn,j) => {
                                                return(
                                                    <li  key={'sg1_'+j}>
                                                        <a  key={'sg1l_'+j} href={mn?.target_url} >
                                                        <i className={social_class[mn?.menu_text]} aria-hidden="true"></i>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }

                {/* <li>
                    <a id="ctl00_Footer1_SocialPosition01" href="https://twitter.com/OrthoClinicalDX">
                        <i className="fab fa-twitter" aria-hidden="true"></i></a>
                </li>
                <li>
                    <a id="ctl00_Footer1_SocialPosition02" href="https://www.facebook.com/OrthoClinicalDX/">
                        <i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                </li>
                <li>
                    <a id="ctl00_Footer1_SocialPosition03" href="https://www.linkedin.com/company/ortho-clinical-diagnostics/">
                        <i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                </li>
                <li>
                    <a id="ctl00_Footer1_SocialPosition04" href="https://www.instagram.com/orthoclinicaldx/?hl=en">
                        <i className="fab fa-instagram" aria-hidden="true"></i></a>
                </li> */}
            </ul>
        </div>
    </div>

  </div>
{/* MENUS HORIZONTAL ROW 1 MENUS HORIZONTAL ROW 1 MENUS HORIZONTAL ROW 1 MENUS HORIZONTAL ROW 1  */} 
 
  <div className="divider-md"></div>

  {/* FOOTER CONTENT FOOTER CONTENT FOOTER CONTENT FOOTER CONTENT FOOTER CONTENT  */}
  <div className="footer-content d-none d-sm-block">
                        <div className="row">
                                <div className=" d-none d-sm-block col-sm-12 col-md-6 ">
                                        <span className="title" data-toggle="ci-links" style={{fontSize:"12px !important"}}  >
                                            <span id="ctl00_Footer1_Menu01Heading" className="footer-title">
                                                {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 3 )?.i18n_group_key)}
                                            </span> 
                                                <i className="icon footer-drop-down d-block d-md-none fa fa-caret-down" aria-hidden="true"></i>
                                        </span>
                                        <ul className="footer-list" id="ci-links" style={{padding:"0px",columns:"2"}}>
                                        {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 3 )?.menus.map((mn,j) => {
                                                return(
                                                    <li  key={'g1_'+j}>
                                                        <a key={'g1l_'+j} href={mn?.target_url} >
                                                        {t(mn?.i18n_menu_key)}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                            {/* <li>
                                                <a id="ctl00_Footer1_Menu01Pos01" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-xt-7600">VITROS® XT 7600 Integrated System</a>
                                            </li>
                                            <li>
                                                <a id="ctl00_Footer1_Menu01Pos11" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-hiv-combo-test">VITROS® HIV Combo Test</a>
                                            </li>
                                            <li>
                                                <a id="ctl00_Footer1_Menu01Pos12" href="https://extqa.orthoclinicaldiagnostics.com/en-US/home/vitros_b_r_a_h_a_m_s_pct_assay">VITROS® B•R•A•H•M•S PCT Assay</a>
                                            </li> */}
                                            </ul>
                                </div>
                                <div className=" d-none d-sm-block col-sm-12 col-md-3 ">

                                <span className="title" data-toggle="tm-links" style={{fontSize:"12px !important"}}>
                                            <span id="ctl00_Footer1_Menu02Heading" className="footer-title">
                                            {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 4 )?.i18n_group_key)}
                                            </span> 
                                                <i className="icon footer-drop-down d-block d-md-none fa fa-caret-down" aria-hidden="true"></i>
                                </span>
                                <ul className="footer-list" id="tm-links" style={{padding:"0px"}}>
                                {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 4 )?.menus.map((mn,j) => {
                                                return(
                                                    <li  key={'g1_'+j}>
                                                        <a  key={'g1l_'+j} href={mn?.target_url} >
                                                        {t(mn?.i18n_menu_key)}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                            {/* <li>
                                                <a id="ctl00_Footer1_Menu02Pos01" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/ortho-vision-vision-max">ORTHO VISION® Analyzer</a>
                                            </li>
                                            <li>
                                                <a id="ctl00_Footer1_Menu02Pos02" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/ortho-vision-vision-max">ORTHO VISION® Max Analyzer</a>
                                            </li>
                                            <li>
                                                <a id="ctl00_Footer1_Menu02Pos05"></a>
                                            </li> */}
                                        </ul>

                                </div>
                                <div className=" d-none d-sm-block col-sm-12 col-md-3 ">

                                <span className="title" data-toggle="support-links" style={{fontSize:"12px !important"}}>
                                            <span id="ctl00_Footer1_Menu03Heading" className="footer-title">
                                            {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 5 )?.i18n_group_key)}
                                            </span> 
                                                <i className="icon footer-drop-down d-block d-md-none fa fa-caret-down" aria-hidden="true"></i>
                                </span>
                                <ul className="footer-list" id="support-links" style={{padding:"0px"}}>
                                {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 5 )?.menus.map((mn,j) => {
                                                return(
                                                    <li  key={'g1_'+j}>
                                                        <a key={'g1l_'+j} href={mn?.target_url} >
                                                        {t(mn?.i18n_menu_key)}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                            {/* <li>
                                                <a id="ctl00_Footer1_Menu03Pos01" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/lab-reliability">Lab Reliability</a>
                                            </li>
                                            <li>
                                                <a id="ctl00_Footer1_Menu03Pos02" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/lab-optimization">Lab Optimization</a>
                                            </li>
                                            <li>
                                                <a id="ctl00_Footer1_Menu03Pos05"></a>
                                            </li> */}
                                        </ul>
                                </div>
                               
                        </div>
                    </div>
{/* second level menus for mobile layout */}
   <div style={{border:"0px solid red"}} className="d-block d-sm-none">
       
    <div className="row">

        <div onClick={() => this.sec2SubMenusToggle('s1')} className="col-sm-12" style={{cursor:"pointer", fontSize:"14px", paddingTop:"20px", paddingBottom:"20px", borderTop:"2px solid #747678", borderBottom:"2px solid #747678"}}>
        <span>
        {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 3 )?.i18n_group_key)}
            </span>
            <i id="s1subC" className="icon footer-drop-down d-block d-md-none fa fa-caret-down float-right" aria-hidden="true"></i>
            <div id="s1subm" style={{display:"none", border:"opx solid red",paddingTop:"15px"}} className="mcSec2Anchors">
            {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 3 )?.menus.map((mn,j) => {
                                                return(
                                                        <a  key={'g_m_1l_'+j} href={mn?.target_url} >
                                                                {t(mn?.i18n_menu_key)}
                                                        </a>
                                                )
                                            })
                                        }
                {/* <a id="ctl00_Footer1_Menu01Pos01" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-xt-7600">VITROS® XT 7600 Integrated System</a>
                <a id="ctl00_Footer1_Menu01Pos02" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-5600">VITROS® 5600 Integrated System</a>
                <a id="ctl00_Footer1_Menu01Pos06" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-ECiQ">VITROS® ECiQ Immunodiagnostic System</a> */}
            </div>
        </div>

        <div onClick={() => this.sec2SubMenusToggle('s2')}  className="col-sm-12" style={{cursor:"pointer", fontSize:"14px", paddingTop:"20px", paddingBottom:"20px", borderTop:"2px solid #747678", borderBottom:"2px solid #747678"}}>
        <span>
        {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 4 )?.i18n_group_key)}
        </span>
            <i id="s2subC"  className="icon footer-drop-down d-block d-md-none fa fa-caret-down float-right" aria-hidden="true"></i>
            <div id="s2subm" style={{display:"none", border:"opx solid red",paddingTop:"15px"}} className="mcSec2Anchors">
            {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 4 )?.menus.map((mn,j) => {
                                                return(
                                                        <a key={'g_m1_1l_'+j} href={mn?.target_url} >
                                                            {t(mn?.i18n_menu_key)}
                                                        </a>
                                                )
                                            })
                                        }
                {/* <a id="ctl00_Footer1_Menu01Pos07" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-xt-7600">VITROS® XT 7600 Integrated System</a>
                <a id="ctl00_Footer1_Menu01Pos08" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-5600">VITROS® 5600 Integrated System</a>
                <a id="ctl00_Footer1_Menu01Pos09" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-4600">VITROS® 4600 Chemistry System</a> */}
            </div>
        </div>

        <div onClick={() => this.sec2SubMenusToggle('s3')}  className="col-sm-12" style={{cursor:"pointer",fontSize:"14px", paddingTop:"20px", paddingBottom:"20px", borderTop:"2px solid #747678", borderBottom:"2px solid #747678"}}>
        <span>
        {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 5 )?.i18n_group_key)}
            </span>
            <i id="s3subC"  className="icon footer-drop-down d-block d-md-none fa fa-caret-down float-right" aria-hidden="true"></i>
            <div id="s3subm" style={{display:"none", border:"opx solid red",paddingTop:"15px"}} className="mcSec2Anchors">
            {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 5 )?.menus.map((mn,j) => {
                                                return(
                                                        <a  key={'g_m2_1l_'+j} href={mn?.target_url} >
                                                            {t(mn?.i18n_menu_key)}
                                                        </a>
                                                )
                                            })
                                        }
                {/* <a id="ctl00_Footer1_Menu01Pos010" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-xt-7600">VITROS® XT 7600 Integrated System</a>
                <a id="ctl00_Footer1_Menu01Pos012" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-5600">VITROS® 5600 Integrated System</a>
                <a id="ctl00_Footer1_Menu01Pos016" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/vitros-ECiQ">VITROS® ECiQ Immunodiagnostic System</a> */}
            </div>
        </div>

    </div>

    </div>  
{/* second level menus for mobile layout */}                             
  {/* FOOTER CONTENT FOOTER CONTENT FOOTER CONTENT FOOTER CONTENT FOOTER CONTENT  */}

  {/* MENUS HORIZONTAL ROW 2 MENUS HORIZONTAL ROW 2 MENUS HORIZONTAL ROW 2 MENUS HORIZONTAL ROW 2  */}
<div className="row">
                        <div className="col-12">
                            <div className="footer__quick-links  text-capitalize ">
                                <ul className="list-unstyled f12" >
                                {this.state?.footerMenus.find( (gp,i) => 
                                            gp?.position === 6 )?.menus.map((mn,j) => {
                                                return(
                                                    <li  key={'g1_'+j}>
                                                        <a key={'g1l_'+j} href={mn?.target_url} className="text-inherit" >
                                                        {t(mn?.i18n_menu_key)}
                                                        </a>
                                                    </li>
                                                )
                                            })
                                }
                                    {/* <li>
										<a id="ctl00_Footer1_LegalPosition01" className="text-inherit" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/privacy-notice">Privacy Notice</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition02" className="text-inherit" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/legal-notice">Legal Notice</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition03" className="text-inherit" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/patent-lis">Patents</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition04" className="text-inherit" href="https://orthoplusqa.orthoclinicaldiagnostics.com/en-US/home/cookie-notice-disclosure">Cookie Notice &amp; Disclosure</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition05" className="text-inherit" href="https://www.orthoclinicaldiagnostics.com/webresources/File%20Library/pdf/en/CA-OCD-NOTICE.pdf">Declaration for California Compliance for HCP Providers</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition06" className="text-inherit" href="https://extqa.orthoclinicaldiagnostics.com/assets/pdf/US/Ortho_CodeofConduct_English_092016_ExPost.pdf">Our Code Of Conduct</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition07" className="text-inherit" href="https://extqa.orthoclinicaldiagnostics.com/en-US/home/cybersecurity">Cybersecurity</a>
                                    </li>
                                    <li>
										<a id="ctl00_Footer1_LegalPosition08"></a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
</div>
{/* MENUS HORIZONTAL ROW 2 MENUS HORIZONTAL ROW 2 MENUS HORIZONTAL ROW 2 MENUS HORIZONTAL ROW 2  */}  

{/* FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH  */}
<div className="container">

                    <div className="row">
                        <div className="col-md-10 col-sm-10 col-xs-12 foot-text-align">
                            <span className="footer__copyright">
                            {t(this.state?.footerMenus.find( (gp,i) => 
                                                gp?.position === 7 && gp.render_as_paragraph == true)?.i18n_description_key)}
                            
                                {/* <sup><small>Ⓒ</small></sup> 2021 
                                <span id="ctl00_Footer1_FooterCoName"> Ortho Clinical Diagnostics</span>. 
                                <span id="ctl00_Footer1_FooterText">
                                    Ortho Clinical Diagnostics, publishes this site and is solely responsible for its content. The availability of the products is subject to compliance with the regulatory requirements of each market.
                                    <br/> *This website contains information which is targeted to a wide range of audiences and could contain product details or information otherwise not valid or applicable to your country.  
                                    Please remember to consult your local legal restrictions, regulations, registrations or intended uses in the country of your origin.  Product availability may vary from country to country and is subject to varying regulatory requirements.
                                </span> */}
                                <br/><br/>
                            </span>
                            <div className="mb-4"></div>
                        </div>
                        <div className=" col-xs-12 ">
                            <div className="dropdown dropdown--custom-footer bootstrap-select float-right" style={{marginRight: "85px"}}>
                                <i className="fas fa-globe-americas" aria-hidden="true" style={{marginRight:"5px"}}></i>
                                
                                <select name="slct" onChange={this.changeLang} value={this.state.language} id="footer-select-picker" className="selectpicker dropup footer_i8n_dropup" data-dropup-auto="false" data-size="5">
                                    <option value="en" className="dropup-options">English</option>
                                    <option value="fr" className="dropup-options">français</option>
                                </select>

                            </div>
                        </div>
                    </div>

</div>
{/* FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH FOOTER PARAGRAPH  */}
</div>

</footer>    


                </>
        );
    }
}

export default withTranslation()(Footer);