import React from 'react';
import LoginStore from '../Foundations/Auth/LoginStore';
import { Link } from "react-router-dom";
import $ from 'jquery'; 

import headerMenuApiCallService from '../../services/uiHeaderApiCall.service';

import {
    Nav,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    NavItem
} from 'reactstrap';

import i18n from "i18next";
import { withTranslation } from "react-i18next";

const mobileMenuToggle=()=>{
  // close sub menu  mobile layout if sub menu is visible
  if($(".navbar-collapse div").is(":visible")){
    $(".navbar-collapse").collapse('hide');
  } 
  // close sub menu  mobile layout if sub menu is visible
}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false,
            name: LoginStore.displayName(),
            email: LoginStore.displayEmail(),
            headerMenus: []
        };
    }

    async componentDidMount() {
        if (sessionStorage.getItem("encodedUserToken") != null)
            this.setState({
                loggedIn: true
            });
        const uiHeaderMenus = await headerMenuApiCallService();
        this.setState({headerMenus:uiHeaderMenus})
    }

    
    // changeLang = (language) => {
    //     i18n.changeLanguage(language);
    //     mobileMenuToggle();
    // }

    logout() {
        sessionStorage.removeItem('encodedUserToken');
        window.location.href = '/';
    }

    render() {
        const { t } = this.props;

        return (
                <>
                 
{/* <pre>{JSON.stringify(this.state.headerMenus, null, 2)}</pre> */}
<nav className="navbar navbar-expand-lg navbar-light   header-navbar resp-logo-container">
  <div className="top-logo-bar">
    <div className="ortho-logo-container">
        {this.state.headerMenus.map((logourl, k) => {
            if (logourl?.i18n_menu_key == 'header.BacktoOrthoPlus') {
                return (
                    <a href={logourl?.target_url} id="ctl00_OrthoPlus_Logo" className="ortho-logo"></a>
                    )
            }
        })}
    </div>
  </div>

  <button className="navbar-toggler" style={{border:'0px solid white'}} type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
    {/* <span className="navbar-toggler-icon"></span> */}
    <span className="nav-button fas fa-bars hamburger"></span>
  </button>
  
  <div className="  collapse navbar-collapse menu-container" id="navbarScroll"  >
      <div className="resp-menu-container">
  <ul className="navbar-nav mr-auto my-2 my-lg-0 navbar-nav-scroll " style={{}}>
{/* NORMAL MENUS FOR DESKTOP VIEW */}
{this.state.headerMenus.map((hmenu,i) => {
  
    if (hmenu?.subMenus == null   ) {
      
      if (hmenu?.desktop_view_icon!=null && hmenu?.render_in_user_menu == false ){
        return(
            <li className="nav-item d-none d-lg-block " key={'a' + i} >
                <a className="nav-link menu0" href={hmenu.target_url} >
                    <img className="" style={{marginTop:"2px"}} data-toggle="tooltip" data-placement="bottom" title={t(hmenu.i18n_menu_key)} src={hmenu.desktop_view_icon} data-original-title={t(hmenu.i18n_menu_key)} />
                </a>
        </li>
        )
      } else {
        if (  hmenu?.render_in_user_menu == false ){
          return (<li className="nav-item" key={'a'+i}>
          <Link className="nav-link menu0" to={hmenu.target_url} >{t(hmenu.i18n_menu_key)}</Link>
          </li>)
        }
      }
      
    } else {
      return(  <li className="nav-item dropdown" key={'a'+i}>
      <a className="nav-link menu0 dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
        {t(hmenu.i18n_menu_key)}
        </a>
        <ul className="dropdown-menu menu_block"  aria-labelledby="navbarScrollingDropdown">
            {hmenu.subMenus.map((smenu,j) => {
              return (
                <a className="nav-link menu1" href={smenu.target_url} key={'b'+j} >{t(smenu.i18n_menu_key)}</a>
              )
            }
            )}
        </ul>
      </li>  )     
    }
  
}
)}
      {/* <li className="nav-item">
        <Link className="nav-link menu0" to="/">Home</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link menu0" to="/">{t("nav.dashboard")}</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link menu0" to="/wells">{t("nav.orgs")}</Link>
      </li> */}

{/* NORMAL MENUS FOR DESKTOP VIEW */}      
{/* MENUS WHICH ARE ICONS AS ICONS FOR DESKTOP */}
      {/* <li className="nav-item d-none d-lg-block ">
        <img className="menu0" style={{marginTop:"2px"}} data-toggle="tooltip" data-placement="bottom" title="" 
          src="/Images/Icon_Subscription_dark.png"
          data-original-title="Manage Subscriptions"/>
      </li>
      <li className="nav-item d-none d-lg-block ">
        <img className="menu0" style={{marginTop:"2px"}}  data-toggle="tooltip" data-placement="bottom" title="" 
          src="/Images/Icon_Message_dark.png"
          data-original-title="Communications"/>
      </li>
      <li className="nav-item d-none d-lg-block ">
        <img className="menu0" style={{marginTop:"2px"}}  data-toggle="tooltip" data-placement="bottom" title="" 
          src="/Images/Icon-Notifications-dark.png"
          data-original-title="Alerts &amp; Notification"/>
      </li> */}
{/* MENUS WHICH ARE ICONS AS ICONS FOR DESKTOP */}

{/* MENUS WHICH ARE ICONS AS TEXT FOR SMALLER SCREENS */}
{/* HERE TODO SIVA1*/}
      {this.state.headerMenus.map((hmenu,i) => {

        if (hmenu?.subMenus == null   ) {
          if (hmenu?.desktop_view_icon!=null && hmenu?.render_in_user_menu == false ){
              return (
                  <li key={ 'lk' + Math.random()} className="nav-item d-block d-lg-none ">
              <Link className="nav-link menu0" 
                to={hmenu.target_url} 
                key={'ak'+i}>{t(hmenu.i18n_menu_key)}</Link>
            </li>
            )
          }
        }

      }
      )}  
      {/* <li className="nav-item d-block d-lg-none ">
        <Link className="nav-link menu0" to="/wells">Manage Subscriptions</Link>
      </li>
      <li className="nav-item d-block d-lg-none ">
        <Link className="nav-link menu0" to="/wells">Communications</Link>
      </li>
      <li className="nav-item d-block d-lg-none ">
        <Link className="nav-link menu0" to="/wells">Alerts &amp; Notification</Link>
      </li> */}
{/* MENUS WHICH ARE ICONS AS TEXT FOR SMALLER SCREENS */}

      {/* EXAMPLE FOR SUB MENUS 
      <li className="nav-item dropdown">
        <a className="nav-link menu0 dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
        {t("language")}
        </a>
        <ul className="dropdown-menu menu_block"  aria-labelledby="navbarScrollingDropdown">
            <li><a className="nav-link menu1-header"  >
              <i className="fas fa-globe pr10"></i>Select a Language</a>
            </li>
            <li><hr className="dropdown-divider"/></li>
            <li className="nav-link menu1" onClick={() => this.changeLang('en')}>English</li>
            <li className="nav-link menu1" onClick={() => this.changeLang('fr')}>français</li>
        </ul>
      </li>  
      EXAMPLE FOR SUB MENUS */}
      {/* <li className="nav-item dropdown">
      <a className="nav-link menu0 dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
        {t("language")}
        </a>
        <ul className="dropdown-menu menu_block"  aria-labelledby="navbarScrollingDropdown">
            <li><a className="nav-link menu1-header"  >
              <i className="fas fa-globe pr10"></i>Select a Language</a>
            </li>
            <li><hr className="dropdown-divider"/></li>
            <li className="nav-link menu1" onClick={() => this.changeLang('en')}>English</li>
            <li className="nav-link menu1" onClick={() => this.changeLang('fr')}>français</li>
        </ul>
      </li>  */}
      
{/* USER DETAILS & LOGOUT MENU FOR DESKTOP       */}
                                <li key={'lk'+Math.random()}className="nav-item dropdown user-menu">
        <a className="nav-link menu0 dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
        {this.state.name}
        </a>

        <ul className="dropdown-menu dropdown-menu-right menu_block " aria-labelledby="navbarScrollingDropdown" >
                                        <li><a key={'ll'+Math.random() } className="nav-link menu1-header"  >
              {this.state.name}<br/>
                                            <span className="red-color">{this.state.email}</span></a></li>
            {this.state.headerMenus.map((hmenu,i) => {

              if (  hmenu?.render_in_user_menu == true ){
                return(
                  <a className="nav-link menu1" href={hmenu.target_url}  key={'um'+i} >
                    <i className="   "></i>{t(hmenu.i18n_menu_key)}
                  </a>
                )
              }

            }
         )}
            <a className="nav-link menu1" href="#" onClick={this.logout}>{t("nav.logout")}</a>
        </ul>
      </li>
{/* USER DETAILS & LOGOUT MENU FOR DESKTOP       */}      

{/* LOGOUT MENU PULLED OUT FOR SMALLER SCREENS AS USER DETAILS RENDERED IN PANEL IN SMALLER SCREENS */}
      {this.state.headerMenus.map((hmenu,i) => {

      if (  hmenu?.render_in_user_menu == true ){
          return (
              <li key={'lz'+Math.random() } className="nav-item user-logout">
          <Link className="nav-link menu0" to={hmenu.target_url} key={'um_m_'+i} >
          {t(hmenu.i18n_menu_key)}
            </Link>
          </li>
        )
      }

      }
      )}
{/* LOGOUT MENU PULLED OUT FOR SMALLER SCREENS AS USER DETAILS RENDERED IN PANEL IN SMALLER SCREENS */}

{/* user details bottom panel for mobile */}
<div className="  mobile-user-footer">
<div className="top-panel__footer " >
    <div  >
        <div className="userMobileContent">
            <div className="row"  >
            <div className="col-sm-12  user-name"  >
                <b>
            <span id="UserInfo3_lbl" >{this.state.name}</span>
                </b>
            </div>
            </div>
            <div className="user-email">
            <a id="ctl00_UserInfo3_lnk_UserLink1" 
            href="mailto:xxxxxx@orthoclinicaldiagnostics.com" 
            >xxxxxx@orthoclinicaldiagnostics.com</a>
            </div>
        </div>
    </div>
</div>
</div>
{/* user details bottom panel for mobile */}

    </ul>
    </div>
  </div>

</nav>

                </>
        );
    }
}

export default withTranslation()(Header);