const VasDashboardClient = require('vas-dashboard-client');
// const defaultClient = VasDashboardClient.ApiClient.instance;
// defaultClient.basePath = 'https://localhost:44394';

const footerMenuApiCall = ( ) => {
    // The next line gets an instance of the ApiClient (bearerAuth already set in Full.js)
    let apiInstance = new VasDashboardClient.DefaultApi();

    return new Promise((resolve, reject) => {
        apiInstance.listFooterMenus(  (error, data) => {
            resolve(data)

        })
    })
}

export default footerMenuApiCall