import React from 'react'
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

/*
 * same as you did for the login page, create a page for the token response 
 * (i.e. /assertion-handler?token=xxxx or /assertion-handler/xxxx), 
 * grab it from the props in the constructor and then use localStorage.setItem('encodedUserToken', xxx);
 */

class VASAssertionHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null
        };
        const jwt = new URLSearchParams(this.props.location.search).get('jwt');

        //if (error)
        //    document.getElementById("error").style.display = "block";
        //else {
        sessionStorage.setItem('encodedUserToken', jwt);
        this.setState({ redirect: "/dashboard" });
        //}

    }

    render() {
        const { t } = this.props;
        //if (this.state.redirect) {
            return <Redirect to="/dashboard" />
        //}
    }
}

export default withTranslation()(VASAssertionHandler);

