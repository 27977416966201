
import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router';
import LoginStore from './components/Foundations/Auth/LoginStore';

import UserLayout from './components/Layouts/User';
import PlatformLayout from './components/Layouts/Platform';

import Login from './pages/Auth/Login';
import AssertionHandler from './pages/Auth/AssertionHandler.js';
import OrgsSelect from './pages/Orgs/Select';
import DashboardMain from './pages/Dashboard/Main';
import Dashboard from './pages/Dashboard/Full';

// These are pages that are rendered using the basic error template 
const errorPageRoutes = [
    '/404',
    '/500',
    '/maintenance'
];

// These are pages that are rendered using the basic user template 
const userPageRoutes = [
    '/login',
    '/logout',
    '/assertionhandler/token-relay',
];

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route exact {...rest} render={(props) => (
        LoginStore.isLoggedIn() === true
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
)

const Routes = ({ location }) => {
    
    if (errorPageRoutes.indexOf(location.pathname) > -1) {
        return (
            <div className="App">
                <h1>Error Page</h1>
            </div>
        )
    } else if (userPageRoutes.indexOf(location.pathname) > -1) {
        return (
            <UserLayout>
                <Switch location={location}>
                    <Route path="/login" component={Login} />
                    <Route path="/assertionhandler/token-relay" component={AssertionHandler} />
                </Switch>
            </UserLayout>
        )
    } else {

        return (
            <PlatformLayout>
                <Switch>
                    <PrivateRoute path='/orgs' component={OrgsSelect} />
                    <PrivateRoute path='/dashboard' component={Dashboard} />
                    <PrivateRoute path='/dashboardOld' component={DashboardMain} />
                    <Redirect from="/" to="/dashboard" />
                </Switch>
            </PlatformLayout>
        )
    }
}

export default withRouter(Routes);