import React from 'react'
import Chart from "react-apexcharts";
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card, Spinner } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

const TIMER_INTERVAL = 1000;
const VasDashboardClient = require('vas-dashboard-client');
const defaultClient = VasDashboardClient.ApiClient.instance;
defaultClient.basePath = process.env.REACT_APP_VAS_DASHBOARD_API_BACKEND_HOSTNAME;

class VASGauge extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            liveData: false,
            series: [],
            change: 0.00,
            options: {
                chart: {
                    height: 350,
                    type: 'radialBar',
                    offsetY: -10
                },
                plotOptions: {
                    radialBar: {
                        track: {
                            background: "#fff"
                        },
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            name: {
                                fontSize: '16px',
                                color: undefined,
                                offsetY: 120
                            },
                            value: {
                                offsetY: 76,
                                fontSize: '22px',
                                color: undefined,
                                formatter: val => {
                                    return val + "%"; // PLACE FORMATTER HERE (percent or other)
                                }
                            }
                        }
                    }
                },
                fill: { // GREEN: #2fce57, RED: #ce2f45
                    colors: []
                },
                stroke: {
                    dashArray: 4
                },
                labels: [''],
            },
            dataIsLoaded: false
        };

        this.handleLiveDataToggle = this.handleLiveDataToggle.bind(this);
    }

    handleLiveDataToggle(e) {
        let isChecked = e.target.checked;

        if (isChecked) {
            this.setState({
                liveData: true
            });

            this.liveDataTimer = setInterval(() => {
                this.getLatestData()
            }, TIMER_INTERVAL);
        }
        else {
            this.setState({
                liveData: false
            });

            clearInterval(this.liveDataTimer)
        }
    }

    getLatestData() {
        let options = this.state.options;
        let series = this.state.series;
        let change = this.state.change;
        let apiInstance = new VasDashboardClient.DefaultApi();
        apiInstance.getMetricLatest(3, (error, data) => {
            if (error)
                console.log("error: " + error);
            else {
                options['labels'][0] = data['ts'];
                series[0] = data['value'];
                change = data['change'];
            }

            if (change > 0)
                options['fill']['colors'] = "#2dce89";
            else if (change < 0)
                options['fill']['colors'] = "#ce2f45";
            else
                options['fill']['colors'] = "#8898aa";

            this.setState({
                options: options,
                series: series,
                change: change,
                dataIsLoaded: true
            });
            //console.log(this.state.options)
        });
    }


    componentDidMount() {
        this.getLatestData();
    }

    render() {
        const { t } = this.props;
        return (
            <div className="row justify-content-center">
                <Card className="bg-secondary" style={{ width: '50rem' }}>
                    <Card.Body>
                        <h1 className="display-3 text-dark mb-4">{t("gauge.title")}</h1>
                        <div className="d-inline-block">
                            <Card.Title>{t("gauge.latest")}</Card.Title>
                        </div>
                        <div className="d-inline-block form-check pull-right">
                            <input className="form-check-input" type="checkbox" name="latest-data-live"
                                checked={this.state.liveData} onChange={this.handleLiveDataToggle} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {t("gauge.live")}
                                {this.state.liveData
                                    ? <Spinner variant={"primary"} className="ml-2" animation="grow" size="sm" />
                                    : <Spinner style={{ visibility: "hidden" }} className="ml-2" animation="grow" size="sm" />
                                }
                            </label>
                        </div>

                        {this.state.dataIsLoaded
                            ? <div className="mixed-chart">
                                <Chart options={this.state.options} series={this.state.series} type="radialBar"
                                    height={350} />
                            </div>
                            : <Spinner animation="border" />
                        }

                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default withTranslation()(VASGauge);