import React from 'react'
import {Button, Card, Form} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import {withTranslation} from "react-i18next";

const VasDashboardClient = require('vas-dashboard-client');
const defaultClient = VasDashboardClient.ApiClient.instance;
defaultClient.basePath = process.env.REACT_APP_VAS_DASHBOARD_API_BACKEND_HOSTNAME;


function RedirectToSSO() {

    const ssopath = process.env.REACT_APP_ORTHO_SSO_LOGON_PATH;

    const origin = window.location.origin;

    const redirectLocation = ssopath + origin + '/assertionhandler/token-relay';

    window.location.replace(redirectLocation);
}


class VASLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            redirect: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        let apiInstance = new VasDashboardClient.DefaultApi();
        let ar = new VasDashboardClient.AuthenticateRequest();
        ar.username = this.state.username;
        ar.password = this.state.password;
        let opts = {
            'authenticateRequest': ar
        };
        apiInstance.authenticate(opts, (error, data, response) => {
            if (error)
                document.getElementById("error").style.display = "block";
            else {
                sessionStorage.setItem('encodedUserToken', data['token']);
                this.setState({ redirect: "/figures" });
            }
        });

        e.preventDefault(); // FIXME: replace with legit behavior
    }

    render() {
        const {t} = this.props;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        return RedirectToSSO();

        //    (
        //    <div className="row justify-content-center">
        //        <Card className="bg-secondary" style={{width: '50rem'}}>
        //            <Card.Body>
        //                <Form onSubmit={this.handleSubmit}>
        //                    <h1 className="display-3 mb-4 text-dark">{t("login.title")}</h1>
        //                    <Form.Group controlId="formBasicEmail">
        //                        <Form.Label>{t("login.username")}</Form.Label>
        //                        <Form.Control type="text"
        //                                      value={this.state.value}
        //                                      onChange={e => this.setState({username: e.target.value})}
        //                        />
        //                        <Form.Text className="text-muted">
        //                        </Form.Text>
        //                    </Form.Group>
        //                    <Form.Group className="mt-3" controlId="formBasicPassword">
        //                        <Form.Label>{t("login.password")}</Form.Label>
        //                        <Form.Control type="password"
        //                                      value={this.state.value}
        //                                      onChange={e => this.setState({password: e.target.value})}
        //                        />
        //                    </Form.Group>
        //                    <Form.Group className="mt-1 " controlId="formBasicCheckbox">
        //                        <p id="error" style={{display: "none"}} className="mt-1 text-danger small">
        //                            {t("login.error")}
        //                        </p>
        //                    </Form.Group>
        //                    <Button className="mr-3 mt-2" variant="primary" type="submit">
        //                        {t("login.title")}
        //                    </Button>
        //                </Form>
        //            </Card.Body>
        //        </Card>
        //    </div>
        //);
    }
}

export default withTranslation()(VASLogin);