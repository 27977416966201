import React from 'react';
import VASFigureDetails from "../VASFigureDetails";

class DetailsGraph extends React.Component {
    render() {
        const {
            detailsFormComponent,
            detailsOf: { selectedOrgId, figureCode },
        } = this.props
        const DetailsFormComponent = detailsFormComponent
        // This component is used when a summary graph have details component
        // and we import a details form dynamically by passing extraDetailsOptions to the VasFigure component.
        return (
            <>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            {this.props.detailsCardHeader}
                        </div>
                        <div className="row pl-1">
                            <div className="card-body col-md-3">
                                <DetailsFormComponent
                                    handleViewButtonClick={this.props.handleViewButtonClick}
                                    vasId={this.props.vasId}
                                    testOptions={this.props.testOptions}
                                    analyzerOptions={this.props.analyzerOptions}
                                    detailsOf={this.props.detailsOf}
                                    options={this.props.apiOptions.data.options} // set start/end/priority/test from parent
                                    firstDetailsClick={this.props.firstDetailsClick}
                                />
                            </div>
                            <div className="card-body col-md-9">
                                <VASFigureDetails
                                    selectedOrgId={selectedOrgId}
                                    figureCode={figureCode}
                                    startDate={this.props.apiOptions.data.options.startDate}
                                    endDate={this.props.apiOptions.data.options.endDate}
                                    // if this.props.apiOptions != 'undefined'
                                    //startDate={this.props.apiOptions.data.options.startDate || this.props.startDate}
                                    //endDate={this.props.apiOptions.data.options.endDate || this.props.endDate}
                                    {...this.props}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DetailsGraph;