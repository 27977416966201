import React from 'react';

import { withTranslation } from 'react-i18next';
import OrgSelector from '../../components/OrgSelector';
import LoginStore from '../../components/Foundations/Auth/LoginStore';

import { Row, Col } from "reactstrap";
import ProductionDataCard from '../../components/Elements/ProductionDataCard';
import PerformanceCard from '../../components/Elements/PerformanceCard';
import { AiFillPrinter } from "react-icons/ai";

const VasDashboardClient = require('vas-dashboard-client');

/* Set todays date to last Friday */
const todaysDate = new Date();

const startDate = todaysDate;
startDate.setMonth(todaysDate.getMonth() - 12);

const productionDetailsFilter = { apiOptions: {}}
const tatDetailsFilter = { apiOptions: {}}
const sampleLoadingDetailsfilter = { apiOptions: {} }

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        // These 3 lines get the prototype for the ApiClient and set the bearerAuth to the logged in user's encoded user token globally
        const defaultClient = VasDashboardClient.ApiClient.instance;
        let bearerAuth = defaultClient.authentications['bearerAuth'];
        bearerAuth.accessToken = sessionStorage.getItem("encodedUserToken");

        defaultClient.basePath = process.env.REACT_APP_VAS_DASHBOARD_API_BACKEND_HOSTNAME;

        let featurelist = LoginStore.displayFeatureList();
        let tabid = process.env.REACT_APP_APP_NAME_TAB_ID;
        let authorized = false;
        // Check page authorization and redirect if user not authorized
        if (featurelist.includes(tabid)) {
            // Redirect to /eServices.OrthoPerformance/Error.aspx?msg=NotAuthorizedMsg
            authorized = true;
        }
        this.state = {
            authorized: authorized,
            dataIsLoaded: false,
            propsUpdated: true,
            detailsChanged: false,
            productionCardDetailsFilter: {},
            //sampleLoadingDetailsfilter: {},
            //tatDetailsFilter: {},
            moduleValue: null,
            moduleName: null,
            /**
             * Array of all available orgs for the current context
             *
             */
            availableOrgs: [],

            /**
             * Selected org, currently only 1 allowed but this could become an array in the future if desired.
             * 
             */
            selectedOrg: null,

            selectedOrgId: null,

            selectedVasId: null
        }

        this.printRef = React.createRef();
        this.handleOrgSelectChange = this.handleOrgSelectChange.bind(this);

    }

    /*
    componentWillMount will not exist in future versions of React from version 17 onward.
    So if you want your code to work in future versions of React, you have two choices:
    1. Recommended: use the constructor of the component class instead
    2. Use UNSAFE_componentWillMount
    */
    async UNSAFE_componentWillMount() {
        // The next line gets an instance of the ApiClient with the bearerAuth already set from above
        let apiInstance = new VasDashboardClient.DefaultApi();

        let userid = LoginStore.displayUserID();
        let username = LoginStore.displayUsername().length == 0 ? 'none' : LoginStore.displayUsername();
        let usertype = LoginStore.displayUserType();
        let ecindicator = process.env.REACT_APP_ECONNECTED_INDICATOR;
        let prodgroup = process.env.REACT_APP_PROD_GROUP;
        let prodfamily = process.env.REACT_APP_PROD_FAMILY;
        let excludestatus = process.env.REACT_APP_EXCLUDE_STATUS;

        // Get the orgs/JIM#s available to this user
        apiInstance.listOrgs(userid, username, usertype, ecindicator, prodgroup, prodfamily, excludestatus, (error, data) => {
            if (error) {
                console.log("error: " + error); // error: Error: {"message":"Unauthorized"}
                if (error.status == 401 || error.status == 403) {
                    const ssopath = process.env.REACT_APP_ORTHO_SSO_LOGON_PATH;
                    const origin = window.location.origin;
                    const redirectLocation = ssopath + origin + '/assertionhandler/token-relay';
                    window.location.replace(redirectLocation);
                }
            }
            else {
                this.setState({ availableOrgs: data });
            }
        });
    }

    handleOrgSelectChange(selectedItem) {
        if (this.state.selectedVasId != selectedItem.vasId) {
            this.setState({
                selectedOrgId: selectedItem.orgId,
                selectedOrg: selectedItem,
                selectedVasId: selectedItem.vasId,
                dataIsLoaded: false
            })
        }
    }

    handlePrint = () => {
        const w = window.open();
        if (this.printRef.current?.innerHTML) {
            w?.document.write(this.printRef.current.innerHTML);
            w?.print();
        }
        w?.close();
    }

    render() {
        const StartDate = startDate;
        const EndDate = todaysDate;
        const { t } = this.props;

        return (
            this.state.authorized ?
            <>
                <Row style={{ marginBottom: "15px" }}>
                    <Col sm={{ size: 12 }} md={this.state.selectedOrg && 11}>
                        <OrgSelector
                            availableOrgs={this.state.availableOrgs}
                            handleOrgSelectChange={this.handleOrgSelectChange}
                            selectedOrg={this.state.selectedOrg}
                        />
                    </Col>
                    {this.state.selectedOrg
                        && <Col md={1}>
                            <AiFillPrinter
                                onClick={this.handlePrint}
                                size={30}
                                className='cursor-pointer' />
                        </Col>}
                </Row>
                <div ref={this.printRef} id="section-to-print">
                    <style type="text/css">
                        {"@media print{@page {size:landscape}}"}
                    </style>

                    {/* In both ProductionCard and PerformanceCard Components
                        VasFigureComponent is used.
                        API Data is managed from VasFigureApiCall.service.js file and VasFigure.js
                        
                    */}
                    <ProductionDataCard
                        selectedOrgId={this.state.selectedOrgId}
                        selectedOrg={this.state.selectedOrg}
                        availableOrgs={this.state.availableOrgs}
                        startDate={StartDate}
                        endDate={EndDate}
                        selectedVasId={this.state.selectedVasId}
                        productionCardDetailsFilter={productionDetailsFilter}
                    />

                    <PerformanceCard
                        selectedOrgId={this.state.selectedOrgId}
                        selectedOrg={this.state.selectedOrg}
                        startDate={StartDate}
                        endDate={EndDate}
                        selectedVasId={this.state.selectedVasId}
                        tatDetailsFilter={tatDetailsFilter}
                        sampleLoadingDetailsfilter={sampleLoadingDetailsfilter}
                    />

                </div>

                </> : <><div class="fa-stack-1x"><h1> {t("not-authorized")}</h1></div></>
        )
    }

}

export default withTranslation()(Dashboard)