import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from 'react-select';

class OrgSelector extends Component {

    render() {
        var groupOptions = [];

        this.props.availableOrgs.forEach(aOrg => {
            var aOrgGroupLabel = "";

            if (aOrg.group_name) {
                aOrgGroupLabel = aOrg.group_name;
            } else {
                aOrgGroupLabel = "No Parent";
            }

            if (aOrg.group_id) {
                aOrgGroupLabel = aOrgGroupLabel + " (" + aOrg.group_id + ")";
            }

            var existingIdx = groupOptions.findIndex(e => (e.label === aOrgGroupLabel));

            var currentOption = aOrg;

            if (existingIdx !== -1) {
                var tempOptions = groupOptions[existingIdx].orgs;

                tempOptions.push(currentOption);

                groupOptions[existingIdx].options = tempOptions;

            } else {
                let tempOptions = [];

                tempOptions.push(currentOption);

                groupOptions.push({
                    label: aOrgGroupLabel,
                    orgs: tempOptions
                });
            }
        });

        // console.log(groupOptions);

        var options = groupOptions.map((org_group) => {
            return {
                label: org_group.label,
                value: org_group.label,
                options: org_group.orgs.map((org) => {
                    var labels = [];
                    if (org.labels) {
                        labels = org.labels.split("|");
                    }

                    var aOrgLabel = org.name;
                    aOrgLabel = aOrgLabel + " (" + org.id + ") " + org.vas_id;

                    return {
                        orgId: org.id,
                        key: org.id,
                        label: aOrgLabel,
                        value: org.id,
                        vasId: org.vas_id,
                        labels: labels,
                        last_update_ts: org.last_update_ts
                    }
                })
            }
        })

        const selectStyles = {
            container: (base, state) => ({
                ...base,
                zIndex: "999"
            })
        };

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#000'
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
            float: "right"
        };

        const formatGroupLabel = data => (
            <div style={groupStyles}
            // onClick={() => this.addSelectedByGroup(data.options)}
            >
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        const optionStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: "25px"
        };

        const formatOptionLabel = (data) => {
            var selected = false;

            if (this.props.selectedOrg && (this.props.selectedOrg.value === data.value)) {
                selected = true;
            }

            return (
                <div style={optionStyles}>
                    {data.label}
                    {(!data.last_update_ts && !selected) ?
                        <div class="group-sub-div">
                            <span style={groupBadgeStyles}>
                                {/*Latest Value: {data.last_update_ts}*/}
                            </span>

                            {data.labels.filter((dm, idx) => idx < 5).map((dm, idx, arr) => {
                                if (idx === 0 && arr.length >= 4) {
                                    return (
                                        <span style={groupBadgeStyles}>...</span>
                                    )
                                } else {
                                    return (
                                        <span style={groupBadgeStyles}>
                                            {dm}</span>
                                    )
                                }

                            })}
                        </div>
                        : <span></span>}
                </div>
            )
        };

        return (
            <>
                <div style={{}} >
                    <div style={{ float: "left", width: "auto" }}>
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="">{this.props.t("orgSelector.organization")}: </span>
                        </div>
                    </div>

                    <div>
                        <Select
                            options={options}
                            value={this.props.selectedOrg}
                            formatGroupLabel={formatGroupLabel}
                            formatOptionLabel={formatOptionLabel}
                            styles={selectStyles}
                            placeholder={this.props.t("orgSelector.placeholder")}
                            onChange={this.props.handleOrgSelectChange} />
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(OrgSelector);