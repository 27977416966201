const VasDashboardClient = require('vas-dashboard-client');


// this code need to be changed and everything need to be shared from the backend REST service
// data response should be in the below format 
// [{data: Array}, {data: Array}...]
// if the data format is not in the above format 
// logic need to be changed in HighchartsWrapper Component

const formatDataByForEach = (dataset) => {
    let allData = [];
    dataset.metrics.forEach((metric, idx) => {
        let series = []
        if (metric.chartType === "column" || metric.chartType === "line" || metric.chartType === "spline") {
            for (let i = 0; i < metric.values.length; i++) {
                series.push([
                    dataset.x_axis.values[i],
                    metric.values[i]
                ]);
            }
        }
        if (metric.chartType === "boxplot") {
            for (let i = 0; i < metric.listvalues.length; i++) {
                series.push([
                    metric.listvalues[i][1],
                    metric.listvalues[i][2],
                    metric.listvalues[i][3],
                    metric.listvalues[i][4],
                    metric.listvalues[i][5]
                ]);
            }
        }
        if (metric.chartType === "info") {
            for (let i = 0; i < metric.listvalues.length; i++) {
                series.push([
                    //i,
                    metric.listvalues[i]
                //    metric.listvalues[1],
                //    metric.listvalues[2],
                //    metric.listvalues[3]
                ]);
            }
        }
        allData[idx] = {};
        allData[idx]['data'] = series;
    });

    return allData
}


const vasFigureApiCall = (
    {
        apiOptions = { data: {} }, figureCode, vasId
    }
) => {
    // The next line gets an instance of the ApiClient (bearerAuth already set in Full.js)
    let apiInstance = new VasDashboardClient.DefaultApi();

    return new Promise((resolve, reject) => {
        apiInstance.getFigure(figureCode, vasId, apiOptions, (error, data) => {
            //console.log(vasId)
            //console.log("api service")
            //console.log(apiOptions.data.startDate)
            //console.log(apiOptions.data.endDate)
            //console.log(apiOptions.data.moduleId)
            //console.log(figureCode)
            //console.log(data)
            try {
                const allData = formatDataByForEach(data.datasets[0])
                // console.log("formattedData", figureCode, allData)
                resolve(allData)
            } catch (error) {
                window.location.replace('/');
            }
        })
    })
}

export default vasFigureApiCall